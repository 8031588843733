<template> 
<div> 
 <div v-if="data.themeType=='Tuktuk' || data.themeType=='TuktukStep1'" :class="embed && data.themeType=='Tuktuk' ? 'theme--Tuktuk-embed' : embed && data.themeType=='TuktukStep1' ? 'theme--Tuktuk-embed1' : '' ">  
  <Tuktuk class="embed-wrapper"  :data="{
        showProductImages:data.showProductImages,    
        priceWithoutWhosale,
        productImages,
        cartPrice:price,        
        payload,
        ...data,
        discountLists,
        isSizeWindow:mobileVersion,
        variantImageIndex,
        isVariableItemSelected,
        cartSummary,
        cartQuantity,
        errors,
        courierOptionDataFinal,        
        selectedbumpArray,
        totalPrice,
        VATPrice,
        shippingDiscountValue,
        codPrice,
        deliveryCourierPrice,
        isShowVariantImage,
        show,
        cartTotal, 
        filterAttributes,
        cartMessage,
        isBuyButtonLoading,
        loadingPrice,
        cartMessage,
        shippingWeight:weight,       
       
        embed,
        totalQuantity
      }" 
      :is-mobile="mobileVersion"
      @updateCartQuantity="updateCartQuantity" 
      @addCartItem="addCartItem" 
      :submited="submited" 
      :shippingCourierItems="shippingCourierItems"
      @onSubmitted='submitted' 
      @addToCart="addToCart"
      @showMsg="showMsg"
      @updateCartTotal="updateCartTotal"
      @updateCartSummary="updateCartSummary"
      @applyDiscount="applyDiscount"
      @clearDiscount="clearDiscount" 
      @updateDiscountLists="updateDiscountLists" 
      @updateCheckout='handleAutofill'
    />  
  </div> 
  <div v-else id="embed-checkout" class="embed-wrapper" :class='popup ? "" : "container-sm"'>  
    <div class="flex justify-center" :class='popup ? "popup-main-wrapper" : embed ? "content-page-empty content-page-empty--embed" : "content-page-empty"'
      :style='popup ? "" : embed ? "margin: 0 auto; margin-bottom: 3em; padding: 15px; width: 100%;max-width: 466px;" : ""'>
      <div class="content content-empty"
      :class="{
        'Nosidebar-width': data.templateType.startsWith('NoSidebar') || embed,
        'RightLeftsidebar-width': !(data.templateType.startsWith('NoSidebar') || embed),
        'nosidebar-narrow-canvas-width': data.templateType === 'NoSidebarNarrowCanvas',
      }">
        <div class='meng-card embed-topSpace'>
          <div :class='popup ? "" : "embed-checkout__container meng-card-body"'>
            <div
              v-if='(data.headerImage && data.themeType == "Classic") || 
                (data.headerImage && data.themeType != "Classic" && data.templateType != "NoSidebar") ||
                data.showTitle && data.title ||
                data.showTagline && data.tagline' 
              class="embed-checkout__header row"
              :class="{'embed-checkout__header_nosidebar-narrow-canvas': data.templateType === 'NoSidebarNarrowCanvas'}" >
              <div
                v-if='(data.headerImage && data.themeType == "Classic") || (data.headerImage && data.themeType != "Classic" && data.templateType != "NoSidebar")'
                class='flex imgBox justify-center col-md-12 p-0'>
                <HeaderImage :headerImage='data.headerImage' :baseUrl="baseUrl" />
              </div>
              <div class='embed-checkout__header__text col-md-12 p-0'>
                <h1 v-show='data.showTitle && data.title'>{{ data.title }}</h1>
                <p v-show='data.showTagline && data.tagline'>{{ data.tagline }}</p>
              </div>
            </div>

            <div class="embed-notopMargin" :class='embed && popup ? "noMarginInMobile" : ""'>
              <div class='embed-checkout-main preview-main embed-bgHide'
              :class="{
                      'checkout-nosidebar classic': data.templateType === 'NoSidebar' && data.themeType === 'Classic',
                      'checkout-nosidebar minimalist': data.templateType === 'NoSidebar' && data.themeType !== 'Classic',
                      'checkout-nosidebar-narrow-canvas classic': data.templateType === 'NoSidebarNarrowCanvas' && data.themeType === 'Classic',
                     }"
              >
                <div class="embed-checkout-main__container" :class="{'main-container-canvas-narrow': data.templateType === 'NoSidebarNarrowCanvas', 'is-mobile': mobileVersion}">
                  <template v-for="(pos, posIndex) in compfieldsPositionMobile">

                    <template v-if="displayDiv(pos.name, 'guaranteeSeal')">
                      <div v-bind:key="posIndex" class="embed-sealHide no-sidebar"
                        v-if='((!mobileVersion && data.showSeals) || (mobileVersion && (pos.showHideButton ? pos[pos.buttonVar] : true))) && (data.templateType == "NoSidebar" || (data.themeType == "Classic" && data.templateType == "LeftSidebar" && mobileVersion))'>
                        <div :class='data.templateType == "NoSidebar" ? "no-sidebar" : ""'>
                          <Seal :data='data' />
                        </div>
                      </div>
                    </template>

                    <!-- RIGHT SIDEBAR -->
                    <div id="right-sidebar" 
                      v-bind:key="posIndex + '-rightSidebar'" 
                      v-if='data.templateType == "RightSidebar"'
                      :class="mobileVersion ? (['guaranteeSeal', 'requestedFields', 'saveThisInformation', 'payment', 'buyButton', 'orderSummary','componentVideo'].includes(pos.name) ? 'rightSideBar' : '') : 'rightSideBar'">
                      <!-- only in embed-popup -->
                      <div v-if="embed && popup">
                        <div class='embed-checkout-left-col checkout-detail embed-leftSidebar-popup-width'
                          :class="data.themeType != 'Classic' ? 'minimalist' : 'classic-right'">
                          <Left :selectedbumpArray="selectedbumpArray" :totalBumpOrderPrice="totalBumpOrderPrice"
                            :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload'
                            :codPrice='codPrice' :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading'
                            :delivery='delivery' :price='price' :weight='weight' :quantity='cartQuantity'
                            :totalPrice='totalPrice' :VATPrice='VATPrice' :isSizeWindow='mobileVersion' :cartSummary="cartSummary"
                            :cartTotal="cartTotal" :showCaptcha="showCaptcha" :pos="pos" :posName="pos.name"
                            :productImages="productImages"
                            :variantImageIndex="variantImageIndex"
                            :posIndex="posIndex" :shippingDiscountValue="shippingDiscountValue"
                            @updateCartTotal="updateCartTotal" @onSave='save'
                            @updateCartSummary='updateCartSummary' @cartOrderSummary='openCartSummary' :errors="errors"
                            @onValidate="validate" :submited="submited" @onSubmitted='submitted' />
                            
                        </div>
                      </div>

                      <!-- :class="mobileVersion ? (['guaranteeSeal', 'requestedFields', 'saveThisInformation', 'payment','buyButton','orderSummary'].includes(posName) ? 'embed-checkout-right-col embed-rightHide checkout-form':'') : 'embed-checkout-right-col embed-rightHide checkout-form'" -->
                      <div class="embed-checkout-right-col embed-rightHide checkout-form"
                        :style="!data.showProductImages && !data.orderSummary && data.bullets.length == 0 ? 'margin:auto;' : ''">
                        <Right :discountLists="discountLists" :selectedbumpArray="selectedbumpArray" :popup='popup' :embed="embed" :data='data'
                          :form='form' :payload='payload' :codPrice='codPrice' :priceWithoutWhosale='priceWithoutWhosale'
                          :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading' :delivery='delivery' :price='price' :weight='weight'
                          :quantity='quantity' :totalPrice='totalPrice' :VATPrice='VATPrice' :isSizeWindow='mobileVersion'
                          :cartSummary="cartSummary" :cartQuantity="cartQuantity" :cartTotal="cartTotal"
                          :showCaptcha="showCaptcha" :pos="pos" :posName="pos.name" :posIndex="posIndex"
                          :productImages="productImages"
                          :variantImageIndex="variantImageIndex"
                          :is-mobile="mobileVersion"
                          :courierOptionDataFinal="courierOptionDataFinal" :shippingDiscountValue="shippingDiscountValue" :filterAttributes="filterAttributes" :isVariableItemSelected="isVariableItemSelected"      
                          @updateCartTotal="updateCartTotal" @addToCart="addToCart" @onSave='save'
                          @cartOrderSummary='openCartSummary' @showMobile="updateMobileView"
                          @updateCheckout='handleAutofill' @updateCartSummary="updateCartSummary"
                          @updateCartQuantity="updateCartQuantity" :errors="errors" @onValidate="validate"
                          :submited="submited" @onSubmitted='submitted' :user="user" @applyDiscount="applyDiscount"  @clearDiscount="clearDiscount" @updateDiscountLists="updateDiscountLists"  />
                        <div v-if='!popup && data.templateType !== "NoSidebar" && displayCopyright(posIndex)'
                          class='bottom-bar-text'>
                          <div class='copyright-content '>
                              <div v-if="data.isPoweredBy" class='copyright-text__poweredby'>
                                  <p class="copyright-text__poweredby__text">Powered by</p> 
                                  <img width="70" height="14" src='@/assets/images/formulir-logo-black.svg'/>
                              </div>
                              <p class="copyright-content__text">Copyright © {{new Date() | year_format}}</p>
                          </div>
                        </div>
                      </div>

                      <div class='embed-checkout-left-col embed-leftHide checkout-detail'
                        :class="data.themeType != 'Classic' ? 'minimalist' : 'classic-right'"
                        v-if='(data.themeType != "Classic" || (data.themeType == "Classic" && data.templateType != "RightSidebar") || (data.themeType == "Classic" && data.templateType == "RightSidebar" && !mobileVersion))'>
                        <Left :selectedbumpArray="selectedbumpArray" :totalBumpOrderPrice="totalBumpOrderPrice"
                          :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload' :codPrice='codPrice'
                          :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading' :delivery='delivery'
                          :price='price' :weight='weight' :cartQuantity='cartQuantity' :quantity='quantity'
                          :totalPrice='totalPrice' :VATPrice='VATPrice' :cartTotal="cartTotal" :showCaptcha="showCaptcha" :pos="pos"
                          :productImages="productImages"
                            :variantImageIndex="variantImageIndex"
                          :posName="pos.name" :posIndex="posIndex" @updateCartTotal="updateCartTotal"
                          @updateCartSummary='updateCartSummary' :isSizeWindow='mobileVersion' :cartSummary="cartSummary" :shippingDiscountValue="shippingDiscountValue"
                          @onSave='save' @cartOrderSummary='openCartSummary' :errors="errors" @onValidate="validate"
                          :submited="submited" @onSubmitted='submitted' />
                          <div v-if='!popup && data.templateType !== "NoSidebar" && displayCopyright(posIndex)'
                            class='bottom-bar-text'>
                            <div class='copyright-content'>
                                <div v-if="data.isPoweredBy" class='copyright-text__poweredby'>
                                    <p class="copyright-text__poweredby__text">Powered by</p> 
                                    <img  width="70" height="14" src='@/assets/images/formulir-logo-black.svg' />
                                </div>
                                <p class="copyright-content__text">Copyright © {{new Date() | year_format}}</p>
                            </div>
                          </div>
                      </div>

                      <!-- Footer logo in embed-popup -->
                      <div v-if="embed && popup && displayCopyright(posIndex)" class="embed-rightSidebar-popup-footer">
                        <div class='flex justify-center poweredby-text power_block' style="opacity: 0.2;">Powered by
                          <img  width="70" height="14" class='ml-1' src='@/assets/images/formulir-logo-black.svg' />
                        </div>
                        <div class='text-center copyright-texxt mt-10'>Copyright © {{ new Date() | year_format }}</div>
                      </div>

                    </div>

                    <!-- LEFT SIDEBAR  -->
                    <div id="left-sidebar" 
                      v-bind:key="posIndex + '-leftSidebar'" 
                      v-if='data.templateType == "LeftSidebar"'
                      class="leftSideBar">
                      <!-- only in embed-popup -->
                      <div v-if='embed && popup'>
                        <div class='embed-checkout-left-col checkout-detail embed-leftSidebar-popup-width'
                          :class="data.themeType != 'Classic' ? 'minimalist' : 'classic-right'">
                          <Left :selectedbumpArray="selectedbumpArray" :totalBumpOrderPrice="totalBumpOrderPrice"
                            :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload'
                            :codPrice='codPrice' :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading'
                            :delivery='delivery' :price='price' :weight='weight' :quantity='cartQuantity'
                            :totalPrice='totalPrice' :VATPrice='VATPrice' :isSizeWindow='mobileVersion' :cartSummary="cartSummary"
                            :cartTotal="cartTotal" :showCaptcha="showCaptcha" :pos="pos" :posName="pos.name"
                            :productImages="productImages"
                            :variantImageIndex="variantImageIndex"
                            :posIndex="posIndex" :shippingDiscountValue="shippingDiscountValue" @updateCartTotal="updateCartTotal" @onSave='save'
                            @updateCartSummary='updateCartSummary' @cartOrderSummary='openCartSummary' :errors="errors"
                            @onValidate="validate" :submited="submited" @onSubmitted='submitted' />
                        </div>
                      </div>

                      <div class='embed-checkout-left-col embed-leftHide checkout-detail'
                        :class="data.themeType != 'Classic' ? 'minimalist' : 'classic-left'"
                        v-if="data.showProductImages || data.bullets.length || data.orderSummary">
                        <Left :selectedbumpArray="selectedbumpArray" :totalBumpOrderPrice="totalBumpOrderPrice"
                          :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload' :codPrice='codPrice'
                          :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading' :delivery='delivery'
                          :price='price' :weight='weight' :quantity='quantity' :cartQuantity='cartQuantity'
                          :totalPrice='totalPrice' :VATPrice='VATPrice' :isSizeWindow='mobileVersion' :cartSummary="cartSummary"
                          :showCaptcha="showCaptcha" :pos="pos" :posName="pos.name" :posIndex="posIndex"
                          :productImages="productImages"
                          :variantImageIndex="variantImageIndex"
                          :cartTotal="cartTotal" :shippingDiscountValue="shippingDiscountValue"
                          @updateCartTotal="updateCartTotal"
                          @updateCartSummary='updateCartSummary' @onSave='save' @cartOrderSummary='openCartSummary'
                          :errors="errors" @onValidate="validate" :submited="submited" @onSubmitted='submitted' />
                          <div v-if='!popup && data.templateType !== "NoSidebar" && displayCopyright(posIndex)'
                            class='bottom-bar-text'>
                            <div class='copyright-content'>
                                <div v-if="data.isPoweredBy" class='copyright-text__poweredby'>
                                    <p class="copyright-text__poweredby__text">Powered by</p> 
                                    <img  width="70" height="14" class='ml-1' src='@/assets/images/formulir-logo-black.svg' />
                                </div>
                                <p class="copyright-content__text">Copyright © {{new Date() | year_format}}</p>
                            </div>
                          </div>
                      </div>

                      <div class="embed-checkout-right-col embed-rightHide checkout-form"
                        :style="!data.showProductImages && !data.orderSummary && data.bullets.length == 0 ? 'margin:auto;' : ''">
                        <Right :discountLists="discountLists" :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload'
                          :codPrice='codPrice' :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading'
                          :delivery='delivery' :price='price' :weight='weight' :quantity='quantity'
                          :totalPrice='totalPrice' :VATPrice='VATPrice' :isSizeWindow='mobileVersion' :cartSummary="cartSummary"
                          :cartQuantity="cartQuantity" :cartTotal="cartTotal" :showCaptcha="showCaptcha"
                          :selectedbumpArray="selectedbumpArray" :pos="pos" :posName="pos.name" :posIndex="posIndex"
                          :productImages="productImages"
                          :variantImageIndex="variantImageIndex"
                          :is-mobile="mobileVersion"
                          :courierOptionDataFinal="courierOptionDataFinal" :shippingDiscountValue="shippingDiscountValue" :filterAttributes="filterAttributes" :isVariableItemSelected="isVariableItemSelected"      
                          @updateCartTotal='updateCartTotal' @onSave='save' @addToCart="addToCart"
                          @cartOrderSummary='openCartSummary' @updateCartSummary='updateCartSummary'
                          @showMobile="updateMobileView" @updateCheckout='handleAutofill'
                          @updateCartQuantity="updateCartQuantity" :errors="errors" @onValidate="validate"
                          :submited="submited" @onSubmitted='submitted' :user="user" @applyDiscount="applyDiscount"  @clearDiscount="clearDiscount" />
                          <div v-if='!popup && data.templateType !== "NoSidebar" && displayCopyright(posIndex)'
                            class='bottom-bar-text'>
                            <div class='copyright-content'>
                                <div v-if="data.isPoweredBy" class='copyright-text__poweredby'>
                                    <p class="copyright-text__poweredby__text">Powered by</p> 
                                    <img  width="70" height="14" src='@/assets/images/formulir-logo-black.svg' />
                                </div>
                                <p class="copyright-content__text">Copyright © {{new Date() | year_format}}</p>
                            </div>
                          </div>
                      </div>

                      <!-- Footer logo in embed-popup -->
                      <div v-if="embed && popup && displayCopyright(posIndex)" class="embed-leftSidebar-popup-footer">
                        <div class='flex justify-center poweredby-text power_block' style="opacity: 0.2;">Powered by
                          <img  width="70" height="14" class='ml-1' src='@/assets/images/formulir-logo-black.svg' />
                        </div>
                        <div class='text-center copyright-texxt mt-10'>Copyright © {{ new Date() | year_format }}</div>
                      </div>
                    </div>

                    <!-- NO SIDEBAR  -->
                    <div id="no-sidebar" 
                      v-bind:key="posIndex + '-noSidebar'" v-if='data.templateType == "NoSidebar"' 
                      :style='embed ? "margin-top: 0 !important;" : ""'>

                        <div v-if="data.showProductImages || data.bullets.length" class='embed-checkout-left-col'
                          :class="embed && popup ? '' : 'embed-leftHide'">
                          <Left :selectedbumpArray="selectedbumpArray" :totalBumpOrderPrice="totalBumpOrderPrice"
                            :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload'
                            :codPrice='codPrice' :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading'
                            :delivery='delivery' :price='price' :weight='weight' :quantity='quantity'
                            :cartQuantity='cartQuantity' :totalPrice='totalPrice' :VATPrice='VATPrice' :isSizeWindow='mobileVersion'
                            :cartSummary="cartSummary" :showCaptcha="showCaptcha" :pos="pos" :posName="pos.name"
                            :productImages="productImages"
                            :variantImageIndex="variantImageIndex"
                            :posIndex="posIndex" @onSave='save' 
                            :cartTotal="cartTotal"  :shippingDiscountValue="shippingDiscountValue"
                            @updateCartTotal='updateCartTotal'
                            @updateCartSummary='updateCartSummary'
                            @cartOrderSummary='openCartSummary' :errors="errors" @onValidate="validate"
                            :submited="submited" @onSubmitted='submitted' />
                        </div>
                        <div class="embed-checkout-right-col no-sidebar-form">
                          <Right :discountLists="discountLists" :selectedbumpArray="selectedbumpArray" :popup='popup' :embed='embed' :data='data'
                            :form='form' :payload='payload' :codPrice='codPrice'
                            :priceWithoutWhosale='priceWithoutWhosale' :loadingPrice='loadingPrice' :isBuyButtonLoading='isBuyButtonLoading' :delivery='delivery'
                            :price='price' :weight='weight' :noProductOptionQty='quantity' :totalPrice='totalPrice'
                            :VATPrice='VATPrice' :isSizeWindow='mobileVersion' :cartSummary="cartSummary" :cartQuantity="cartQuantity"
                            :cartTotal="cartTotal" :showCaptcha="showCaptcha" :pos="pos" :posName="pos.name"
                            :productImages="productImages"
                            :variantImageIndex="variantImageIndex"
                            :courierOptionDataFinal="courierOptionDataFinal" :shippingDiscountValue="shippingDiscountValue" :filterAttributes="filterAttributes" :isVariableItemSelected="isVariableItemSelected"      
                            :posIndex="posIndex" @updateCartTotal='updateCartTotal' @onSave='save' @addToCart="addToCart"
                            :is-mobile="mobileVersion"
                            @cartOrderSummary='openCartSummary' @updateCartSummary='updateCartSummary'
                            @showMobile="updateMobileView" @updateCheckout='handleAutofill'
                            @updateCartQuantity="updateCartQuantity" :errors="errors" @onValidate="validate"
                            :submited="submited" @onSubmitted='submitted' :user="user" @applyDiscount="applyDiscount"  @clearDiscount="clearDiscount" @updateDiscountLists="updateDiscountLists" />
                        </div>

                        <!-- Footer logo in embed-popup -->
                        <div v-if="embed && popup && displayCopyright(posIndex)" class="embed-popup-footer">
                          <div class='flex justify-center poweredby-text power_block' style="opacity: 0.2;">Powered by
                            <img  width="70" height="14" class='ml-1' src='@/assets/images/formulir-logo-black.svg' />
                          </div>
                          <div class='text-center copyright-texxt mt-10'>Copyright © {{ new Date() | year_format }}</div>
                        </div>
                    </div>

                    <div
                        class="no-sidebar-narrow-canvas"
                        v-if="data.templateType === 'NoSidebarNarrowCanvas' && posIndex === 0"
                        :key="posIndex + '-noSidebarNarrowCanvas'"
                      >
                        <NoSidebarNarrowCanvas
                          :data="data"
                          :relatedData="{
                            selectedbumpArray,
                            totalBumpOrderPrice,
                            popup,
                            embed,
                            data,
                            form,
                            payload,
                            codPrice,
                            priceWithoutWhosale,
                            loadingPrice,
                            isBuyButtonLoading,
                            delivery,
                            discountLists,
                            price,
                            weight,
                            quantity,
                            totalPrice,
                            mobileVersion,
                            cartSummary,
                            cartQuantity,
                            cartTotal,
                            showCaptcha,
                            pos,
                            productImages,
                            totalQuantity,
                            variantImageIndex,
                            courierOptionDataFinal,
                            shippingDiscountValue,
                            filterAttributes,
                            isVariableItemSelected,
                            posIndex,
                            errors,
                            submited,
                            user,
                            VATPrice
                          }"
                          :producedFieldDatas="mobileVersion ? compfieldsPositionMobile.map((pos, posIndex) => ({...pos, posIndex})) : [pos]"
                          :is-mobile="mobileVersion"
                          @updateCartTotal="updateCartTotal"
                          @onSave="save"
                          @addToCart="addToCart"
                          @cartOrderSummary="openCartSummary"
                          @updateCartSummary="updateCartSummary"
                          @showMobile="updateMobileView"
                          @updateCheckout="handleAutofill"
                          @updateCartQuantity="updateCartQuantity"
                          @onValidate="validate"
                          @onSubmitted="submitted"
                          @applyDiscount="applyDiscount"
                          @clearDiscount="clearDiscount"
                          @updateDiscountLists="updateDiscountLists"
                        />
                    </div>

                    <div v-bind:key="posIndex + '-classic'"
                      v-if='data.themeType == "Classic" && displayCopyright(posIndex)' class="bottom-component mt-40"
                      :style="embed && popup && data.testimonials.length == 0 ? 'padding-bottom: 0;margin-top: 0;' : embed && popup && data.testimonials.length ? 'padding-bottom:20px;' : ''">
                      <Bottom :popup='popup' :embed='embed' :data='data' :form='form' :payload='payload' :price='price'
                        :weight='weight' :quantity='quantity' :totalPrice='totalPrice' :VATPrice='VATPrice' @onSave='save' />
                    </div>

                    <div v-bind:key="posIndex + '-common'"
                      v-if='!popup && (data.templateType == "NoSidebar" || data.templateType == "NoSidebarNarrowCanvas") && (data.themeType == "Minimalism" || data.themeType == "Classic") && displayCopyright(posIndex)'
                      class='bottom-bar-text Nosidebar-container'>
                        <div class='copyright-content'>
                          <div v-if="data.isPoweredBy" class='copyright-text__poweredby'>
                              <p class="copyright-text__poweredby__text">Powered by</p> 
                              <img  width="70" height="14" src='@/assets/images/formulir-logo-black.svg' />
                          </div>
                          <p class="copyright-content__text">Copyright © {{new Date() | year_format}}</p>
                        </div>
                    </div>
                  </template>
                  <template v-if='data.templateType == "LeftSidebar" || data.templateType == "RightSidebar"'>
                      <div class='copyright-content copyright-content--leftsidebar--mobile'>
                        <div v-if="data.isPoweredBy" class='copyright-text__poweredby'>
                            <p class="copyright-text__poweredby__text">Powered by</p> 
                            <img  width="70" height="14" src='@/assets/images/formulir-logo-black.svg' />
                        </div>
                        <p class="copyright-content__text">Copyright © {{new Date() | year_format}}</p>
                      </div>
                  </template>
                </div>
                </div>
              </div>
            </div>
          </div>
            <no-ssr>
            <!-- VARIANT MODAL -->
            <vs-dialog overflow-hidden class="pp-modal-vuesax variant-modal pp-modal-choose-variant--mobile" v-model="changeVariant">
              <div class="pp-modal-vuesax__container">
                <div class="pp-modal-vuesax__header">
                    <img class="icon-dark"  width="24px" src="@/assets/icons/Product.svg" alt="" />
                    <h4 class="modal-title">{{ $t('common.chooseVariant')}}</h4>
                </div>
                <template v-if="(!(data.multiplyVariations && data.marketPlaceStyle && !data.cartOrder)) && chosenData && chosenData.price">
                  <hr>
                  <!-- PRODUCT IMAGE -->
                  <div class="pp-modal-choose-variant--mobile__thumbnail">
                    <template v-if="data.showProductImages && productImages.length>0">
                        <!-- PRODUCT IMAGE SINGLE -->
                        <div
                        v-if="variantImageIndex > -1"
                          class="pp-modal-choose-variant--mobile__thumbnail__single"
                        >
                        
                          <img
                            :alt="productImages[variantImageIndex].src"
                            :src="getSizedImageUrl( productImages[variantImageIndex].src,'medium','product-images')   
                            "
                          />
                        </div>
                        <div
                        v-else-if="productImages.length > 0"
                          class="pp-modal-choose-variant--mobile__thumbnail__single"
                        >
                          <img
                            :alt="productImages[0].src"
                            :src="getSizedImageUrl(productImages[0].src,'medium','product-images')"   
                          
                          />
                        </div>
                    </template>
                    <!-- <template v-if="data.showProductImages && productImages[0]">
                    
                      <div
                        v-if="productImages.length <= 1"
                        class="pp-modal-choose-variant--mobile__thumbnail__single"
                      >
                        <img
                          :alt="productImages[0].src"
                          :src="
                            getSizedImageUrl(productImages[0].src,'medium','product-images')
                          "
                        />
                      </div>

                    
                      <div
                        v-else
                        class="pp-modal-choose-variant--mobile__thumbnail__carousel"
                      >
                        <Carousel
                          :type="'product'"
                          :images="productImages"
                          :variantImageIndex="variantImageIndex"
                        />
                      </div>
                    </template> -->

                    <div class="pp-modal-choose-variant--mobile__thumbnail__content">
                      <div class="pp-modal-choose-variant--mobile__thumbnail__content__number">
                        <template v-if="chosenData.priceCompare > 0 && chosenData.priceCompare!=chosenData.price">
                          <h2 class="pp-modal-choose-variant--mobile__thumbnail__content__number__price">Rp{{chosenData.priceCompare | g_number_format}}</h2>
                          <p  class="pp-modal-choose-variant--mobile__thumbnail__content__before-discount">Rp{{chosenData.price | g_number_format}}</p>

                        </template>
                        <template v-else>
                          <h2 class="pp-modal-choose-variant--mobile__thumbnail__content__number__price">Rp{{chosenData.price | g_number_format}}</h2>
                        </template>

                        
                      

                      </div>
                      <p class="pp-modal-choose-variant--mobile__thumbnail__content__variant">{{chosenData.name}}</p>
                    </div>
                  </div>
                </template>
                <!-- <div
                  v-if="data.showProductImages && productImages[0]"
                  class="pp-modal-choose-variant--mobile__thumbnail-container"
                >
                  <img
                    :alt="productImages[0].src"
                    :src="
                      baseUrl + '/uploads/product-images/' + productImages[0].src
                    "
                  />
                </div> -->
            
                <hr v-if="data.showProductImages && (productImages.length == 0 || productImages[0]) && (!data.multiplyVariations || (data.multiplyVariations && data.cartOrder))" class="hr-2">
                <div class="pp-modal-vuesax__main">
                  <template v-if="data.multiplyVariations &&
                    data.marketPlaceStyle &&
                    !data.cartOrder
                    "
                    >
                  
                    <div 
                      :key="'variant' + i" 
                      v-for="(attr, i) in data.variablePrices" 
                      class="variant-option-qty variant-option-qty--no-border"
                      :class="{'mb-10' : i != data.variablePrices.length - 1}"
                    >
                      <div 
                        class="variant-option-qty__content" 
                        :class="isShowVariantImage?'variant-option-qty__content--show-thumbnail':''"
                      >
                        <div 
                          v-if="isShowVariantImage" 
                          class="variant-option-qty__content--show-thumbnail__thumbnail">
                          <img 
                            alt="" 
                            :src="getImageSrc(attr)"                         
                          />
                        </div>
                        <span class="variant-option-qty__content__label" :class="{'overflow-wrap--anywhere':isHasOneWordLongText(attr.name)}">{{ attr.name }}</span>
                      </div>
                      <div class="variant-option-qty__action">
                        <button
                          class="variant-option-qty__action__decrease"
                          @click="decrement(i, 'multipleVariant')"
                        >
                            <img src="@/assets/icons/ic-action-decrease.svg" alt="-"/>
                        </button>
                        <!-- <vs-input
                          min="0"
                          v-model="attr.qty"
                          readonly
                        /> -->
                        <div class="variant-option-qty__action__number">{{ attr.qty }}</div>
                        <button
                          class="variant-option-qty__action__increase"
                          @click="increment(i, 'multipleVariant')"
                        >
                          <img src="@/assets/icons/ic-action-increase.svg" alt="+"/>
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div >
                      <div v-if="data.attributes">
                        <div  v-for="(attr, i) in data.attributes" v-bind:key="i" class="text-left" :class="{'mb-15' : i != data.attributes.length - 1}">
                          <label class="text--label-field mb-10">{{
                            attr.name
                          }}</label>
                          <div class="flex flex-wrap gap-15 variant-selected-btn">
                            <div v-for="(attrval, j) in filteredProductVariant(attr.value, attr.name)" v-bind:key="j" :class="{'hide-variant':data.hideUnavailableVariant && checkOption(attr.name,attrval)}">
                              <div class="above-switch">
                                <selected-green-switch v-if="payload.variablePrices[attr.name + i.toString()]" v-model="payload.variablePrices[attr.name + i.toString()].name                 
                                  " :options="[
                                    {
                                      id: attrval,
                                      text: attrval,
                                      imgSrc:undefined,
                                      disabled:checkOption(attr.name,attrval),
                                      hideUnavailableVariant: data.hideUnavailableVariant
                                      
                                    },
                                  ]" full="99"></selected-green-switch>
                             
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="data.addQuantity" >
                        <div class="add-quantity d-flex justify-between align-items-center mt-20">
                          <label class="text--label-field">{{ $t('common.quantity') }}</label>
                          <div class="col-5 flex increDecre-btn-wrap" style="max-width: 125px; border-radius: 8px">
                            <div class="minus-btn pointer" @click="decrement(0, 'singleVariant')">
                              <div class="bg-pm-btn relative">
                                <div class="horizontal"></div>
                              </div>
                            </div>
                            <div>
                              <vs-input min="0" v-model="cartQuantity"
                                class="vs-form-control qty-input no-bg disabled p-0" />
                            </div>
                            <div class="plus-btn pointer" @click="increment(0, 'singleVariant')">
                              <div class="bg-pm-btn relative">
                                <div class="horizontal"></div>
                                <div class="vertical"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>

                <div v-if="(loadingPrice && data.variablePrices && data.enableProductOptions && data.variablePrices.length>0 && !isVariableItemSelected)" class="alert-warning1 mb-20 title-font error align-items-start d-flex">
                  <img
                    class="mr-10"
                    src="@/assets/icons/error.svg"
                  />
                  Kombinasi variasi ini tidak tersedia
                </div>              
                <div class="pp-modal-vuesax__footer">              
                  <button v-if="data.cartOrder && data.multiplyVariations" class='pp-button-blue--border' :disabled='cartQuantity == 0 ||  (this.data.variablePrices.length > 0 && !isVariableItemSelected)'
                    @click="addToCart('update')">
                    <span>{{ $t('common.addToCart') }}</span>
                  </button>
                
                  <div
                    @click="() => (changeVariant = false)"
                    class="buy-button__default text-white"
                    :style="'background-color:' + data.buyButtonStyle"
                    :disabled="(this.data.variablePrices.length > 0 && !isVariableItemSelected) || (data.multiplyVariations && !data.cartOrder && this.isAnyVariantwithQtyOne.length < 1)"  
                    :class="(this.data.variablePrices.length > 0 && !isVariableItemSelected) || (data.multiplyVariations && !data.cartOrder && this.isAnyVariantwithQtyOne.length < 1) ? 'buy-button__default--disabled' :''"
                  >
                    <span >Buy Now</span>
                  </div>
                </div>
                <transition name="fade" v-on:enter="showMsg">
                  <div  v-if="show" class="variant-modal__alert">
                    <div class="variant-modal__alert__content">
                      <img src="@/assets/icons/grey-tick.svg" alt="" />
                      <div>{{ $t('common.itemAddedToCart') }}</div>
                    </div>
                  </div>
                </transition>
              </div>
            </vs-dialog>

            <!-- ORDER SUMMARY MOBILE-->
            <vs-dialog overflow-hidden class="pp-modal-vuesax variant-modal modal-order-summary--mobile" v-model="cartOrderSummary">
              <div class="pp-modal-vuesax__container">
                <div class="pp-modal-vuesax__header">
                    <img class="icon-black"  src="@/assets/icons/Cart-bucket.png" alt="" />
                    <h4 class="modal-title">{{ $t('common.yourCart') }}</h4>
                </div>
                <hr>
                <div class="pp-modal-vuesax__main">
                  <div class="pp-order-summary cart-summary">
                      <!-- ORDER SUMMARY LIST -->
                    <div class="pp-order-summary__list__item pp-order-summary__list__item--variant-order">
                      <div class="common-font product-name product-title-shrink-checkout mb-15">
                        {{ data.name }}
                      </div>
                      <div class="order-summary-list" v-for="(elem, i) in cartSummary" v-bind:key="i + 'cartSummary'">
                        <div class="d-flex justify-between">
                          <div class="d-flex justify-between align-left m-1">
                            <div>({{ elem.qty }}x)</div>
                            <div class="text-left">
                              <div class="ml-1 overflow-wrap--anywhere" v-for="(elemChild, j) in elem.items" v-bind:key="j">
                                {{ j.substring(0, j.length - 1) }} : {{ elemChild.name }}
                              </div>
                            </div>
                          </div>
                          <div class="cartSummary-price">
                            <div v-if="elem.price == 0">
                              Rp{{ elem.priceOriginal * elem.qty | g_number_format }}
                            </div>
                            <div v-if="elem.priceOriginal && elem.price > 0">
                              <div style="text-align: end;">
                                <div>Rp{{elem.price * elem.qty | g_number_format}}</div>
                                <div class="text-danger" style="text-decoration: line-through;"> Rp{{elem.priceOriginal * elem.qty | g_number_format}}</div>
                              </div>
                            </div>

                        
                          </div>
                          <!-- <div class="cart-price">
                            <div>Rp {{( elem.price * elem.qty) | g_number_format }}</div>
                          </div> -->
                        </div>

                        <div style="text-align: end;" @click="removeFromCart(i)">
                          <img class="light mr-1 removeFromCart" src="@/assets/icons/Delete-grey.svg" alt="" />
                        </div>
                      </div>
                    </div>

                    <!-- TOTAL -->
                    <div class="pp-order-summary__total">
                      <p class="pp-order-summary__total__label">{{ $t('common.total') }}</p>
                      <p class="pp-order-summary__total__number">
                        Rp
                        {{
                          cartSummary.reduce((a, b) => a + (b.price > 0 ? b.price * b.qty : b.priceOriginal * b.qty), 0) | g_number_format
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="pp-modal-vuesax__footer pp-modal-vuesax__footer-full-width--mobile">
                  <div
                    @click="() => (changeVariant = false)"
                    class="buy-button__default text-white"
                    :style="'background-color:' + data.buyButtonStyle"
                  >
                    <span>{{ $t('common.continueOrder') }}</span>
                  </div>
                </div>
              </div>
            </vs-dialog>
          </no-ssr>
        </div>
      </div>
      
    </div>

    <!-- Modal popup for setting confrimation -->
    <!-- <div class="overlay" id="overlay" v-if="confirmBox">
      <div class="confirm-box">      
        <h2>Confirmation</h2>
        <p>{{settingRedirectMessage}}</p>
        <div class="flex justify-center">
         <button class="pp-button-blue--fill" @click="goNext()">Yes</button>    
        </div>
      </div>
    </div> -->
     <no-ssr>
      <div>
        <vs-dialog v-model="confirmBox" class="pp-modal-vuesax pp-modal-vuesax--default" :overflow-hidden="true" :prevent-close="true">
          <div class="pp-modal-vuesax__container">
            <div class="pp-modal-vuesax__main">
              <h2>{{ $t('common.confirmation') }}</h2>
              <p>{{settingRedirectMessage}}</p>
              <div class="flex justify-center">
                <button class="pp-button-blue--fill" @click="goNext()">{{ $t('common.yes') }}</button>    
              </div>
            </div>
          </div>
        </vs-dialog>   
        <div v-if="scripts.length">
          <div v-bind:key="index + 'index'" v-for="(pixel, index) of scripts">
            <!-- Google Tag Manager (noscript) -->
            <iframe v-if="pixel.type == 'gtm'" :src="'https://www.googletagmanager.com/ns.html?id=' + pixel.name" height="0"
              width="0" style="display:none;visibility:hidden"></iframe>
            <!-- End Google Tag Manager (noscript) -->
            <VueScriptComponent :script="pixel.script" />
          </div>
        </div>

        <!-- POP UP EMAIL CONFIRMATION FOR PRODUCT DIGITAL-->
        <vs-dialog class="pop-up-email-confirmation" width="460px" height="332px" v-model="emailConfirmModal">
          <div v-if="emailConfirmModal" class="main-content-email-confirm">

            <div class="header">
              <label class="title">{{ $t("digitalProduct.popUpEmailConfirmation.title") }}</label>
              <label class="subtitle">{{ $t("digitalProduct.popUpEmailConfirmation.subtitle") }}</label>
            </div>

            <hr class="m-0" />

            <div class="form-email">
              <label class="label-email">{{ $t("digitalProduct.popUpEmailConfirmation.input-label") }}</label>
              <vs-input
                class="input-email"
                :class="{'is-invalid': $v.inputEmailConfirm.$invalid && $v.inputEmailConfirm.$dirty}"
                type="email"
                :placeholder="$t('digitalProduct.popUpEmailConfirmation.input-placeholder')"
                v-model="inputEmailConfirm"
                @input="$v.inputEmailConfirm.$touch()"
              />

              <div
                v-if="$v.inputEmailConfirm.$dirty && !$v.inputEmailConfirm.required"
                class="invalid-email"
              >
                {{ $t("validation.emailIsRequired") }}
              </div>
              <div
                v-else-if="
                  $v.inputEmailConfirm.$dirty &&
                  $v.inputEmailConfirm.required &&
                  !$v.inputEmailConfirm.isValidEmail
                "
                class="invalid-email"
              >
                {{ $t("validation.emailIsNotValid") }}
              </div>
            </div>

            <div class="instruction">
              <img class="info" src="@/assets/icons/info.svg" />
              <label class="label">{{ $t("digitalProduct.popUpEmailConfirmation.instruction") }}</label>
            </div>

            <div class="button-container">
              <button class="confirm-button"
              :class="{
                'save': !$v.inputEmailConfirm.$invalid,
                'disabled': $v.inputEmailConfirm.$invalid,
              }"
              @click="onSubmittedPopUpEmailConfirm()" :disabled="$v.inputEmailConfirm.$invalid">Kirim</button>
            </div>
          </div>
        </vs-dialog>
      </div>
    </no-ssr>
  </div>
 
</template>

<script>

import config from './../../config.js'
import Seal from './seal.vue';
import Bottom from './bottom.vue';
import Right from './right.vue';
import Left from './left.vue';
import HeaderImage from '../HeaderImage.vue';
import Tuktuk from './Tuktuk/index.vue';
import SwitchGreenSelected from './../SwitchGreenSelected.vue';
import { getSizedImageUrl } from "@/helpers/imagekitHelper";
import axios from 'axios'
// import VueScriptComponent from 'vue-script-component'
import debounce from "lodash/debounce";
import Cookies from "js-cookie";
import Vue from 'vue'
import {getfbpc} from "../../helpers/getfbpc"
import {getContentId} from '../../helpers/contentIdHelper'
import { createHmac } from "crypto";
const secret = 'formulir2021';
import NoSidebarNarrowCanvas from './template-type/no-sidebar-narrow-canvas';
import { required, email } from 'vuelidate/lib/validators';
import { get } from 'lodash';

import { trackEventSingle, initPixel  } from '@/util/facebookPixel';
import { trackEventSingleTiktok, initTiktokPixel  } from '@/util/tiktokPixel';
import { cloneDeep } from 'lodash';

export default {
  props: ['data','dataProductImages', 'form', 'refresh', 'user', 'popup', 'belongs_to', 'checkout_id', 'domain', 'checkout_data', 'customWidth', 'mobileVersion', 'embed', 'refid','affiliateId', 'shippingCourierTypes', 'shippingCourierItems','setting'],
  data() {
    return {
      discountLists:[],
      automaticDiscountLists:[],
      totalQuantity:0,
      confirmBox:false,
      settingRedirectMessage: this.$t('common.redirectedToForm'),//"By proceeding, you will get redirected to your checkout form",
      baseUrl: config.api.replace('/api', ''),
      DESTINATION_CODE: '',
      delivery: {
        jne: [],
        sicepat: [],
        sap: [],
        iDexpress: [],
        jNt: []
      },
      deliveryCourierPrice: "",
      courierOptionData:[],
      loadingPrice: false,
      isBuyButtonLoading: false,
      orderCreated: false,
      doRedirect: true,
      errors: {},
      submited: false,
      payload: {
        deliveryPrice: 0,
        deliveryCourier: 'jne',
        deliveryType: 'REG',
        address: null,
        variablePrices: {},
        variablePricesValues: {},
        payment: undefined,
        bump: false,
        test: "test",
        dontIncludeSubdistrict: true,
        dontIncludeZipcode: true,
        VAT : { 
          value: 11, 
          amount :0,
          isVAT: false,       
        },
        sellerOriginId:'',
        discount:{
            code:"",
            amount:0,         
          },
        automaticDiscount:{
          code:"",
          amount:0        
        }    
      },
      scripts: [],    
      total_price: 0,
      totalPrice: 0,
      VATPrice:0,
      price: 0,
      priceWithoutWhosale: 0,
      product_name: '',
      product_category_full: '',
      id: '',
      changeVariant: false,
      cartOrderSummary: false,
      cartSummary: [],
      show: false,
      cartQuantity: 1,
      cartTotal: 0,
      showCaptcha: false,
      repeatedOrderCount: 0,
      lastAssignee: undefined,
      selectedbumpArray: [],
      totalBumpOrderPrice: 0,
      isInvalid: false,
      isArchivedOrder: false,
      trackConversion: false,
      trackConversionApiCalled: false,
      isShowVariantImage: false,
      courierOptionDataFinal:[],
      shippingDiscountValue:0,
      selectedAttributes:{},
      filterAttributes:{},
      isVariableItemSelected:false,
      productImages: cloneDeep(this.dataProductImages),
      variantImageIndex: -1,
      chosenData: {},
      cartMessage:"",
      isAnyVariantwithQtyOne: [],
      getSizedImageUrl,
      getfbpc,
      getContentId,
      handleAutofill:()=>{},
      isComponentReady: false,
      emailConfirmModal: false,
      inputEmailConfirm: '',
    }
  },
  validations() {
    return {
      inputEmailConfirm: {
        required,
        isValidEmail: email,
      },
    };
  },
  components: {
    "Right": Right,
    "Left": Left,
    "Bottom": Bottom,
    Seal,
    // VueScriptComponent,
    HeaderImage,
    Tuktuk,
    "selected-green-switch": SwitchGreenSelected,
    NoSidebarNarrowCanvas,
    // Carousel
  },
  filters: {
    // year_format(val) {
    //   return moment(val).format('YYYY')
    // },
  },
  methods: {  
    async getDiscountLists(code='') {   
      const params={
        offset:new Date().getTimezoneOffset(),
        user_id:this.data.user_id,
        size:5,
        page:1        
      }  
      if(code!=''){
        params['discount_code']=code
        params['includeHiden']=true
      }
      const response = await axios.get(config.api +"product/discountList/"+this.data._id,{params});    
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        this.discountLists = response.data.data.filter(d=>d.discount_value.applies_to.type=='spcific_products' && d.discount_value.applies_to.products.length > 0 ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1 : true);
        if(code!=''){
          // apply coupon code directly by copied coupon link
          this.applyDiscount(this.data.discount_code);
        }
        
     
      }else{
        this.discountLists =[];
      }    
    },
     async getAutomaticDiscountLists() {      
      const response = await axios.get(config.api +"product/automaticDiscountsList/"+this.data._id+"?offset="+new Date().getTimezoneOffset()+"&user_id="+this.data.user_id);        
  
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        this.automaticDiscountLists = response.data.data.filter(d=>d.discount_value.applies_to.type=='spcific_products' && d.discount_value.applies_to.products.length > 0 ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1 : true);
      }else{
        this.automaticDiscountLists =[];
      }   
      
      if(this.data.showCoupon) {
        this.applyAutmaticDiscount();      
      }
    },
    uniqueById(arr, key){
      const seen = new Set();
      return arr.filter(item => {
        const value = item[key];
        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });
    },
    updateDiscountLists(discounts,replace=false){
      if(replace){
        this.discountLists =[...discounts];
      }else{
        this.discountLists =[...this.discountLists,...discounts];
      }
      
      this.discountLists=this.uniqueById(this.discountLists, 'title')    
      
    },
    clearDiscount(){
      this.payload.discount.code = ''     
      this.updatePrices();
      this.$forceUpdate();  
    },
    async applyDiscount(code) {       
     // let self=this;
      if (code === '') {
        this.payload.discount.code = ''     
      
        return
      }
       
      if(code){    
        this.resetPrices(); 
        const discount=this.discountLists.find(x=>x.title==code);
        const applied_discount=this.getApplyDiscount(discount);  
        if(applied_discount.discount_amount && this.totalPrice > applied_discount.discount_amount){
          this.payload.discount.code=code;         
          this.payload.discount["amount"]=applied_discount.discount_amount;    
          this.totalPrice=this.totalPrice-applied_discount.discount_amount        
          this.payload.discount["discount_type"]=discount.discount_type;  
          this.payload.discount["discount_value"]=discount.discount_value;  
          this.payload.discount["minimum_requirements"]=discount.minimum_requirements;  
          this.payload.discount["end_date"]=discount.end_date; 
          this.payload.discount["full_end_date"]=discount.full_end_date; 
          this.payload.discount["full_start_date"]=discount.full_start_date;   
          this.$forceUpdate();     
        }else{
          this.payload.discount.code=''
          this.$vs.notification({
            color: "danger",
            position: "top-right",
            text: "Applied coupon does not meet the minimum requirements",
            duration: 2000,
          });   
        }       
      }
    },
    applyAutmaticDiscount(){
      if(this.automaticDiscountLists.length>0){
        this.automaticDiscountLists.map(discount=>{
          const applied_discount=this.getApplyDiscount(discount); 
          discount["amount"]=applied_discount.discount_amount;
          return discount;
        })       
        let maxDiscount = this.automaticDiscountLists.reduce((prev, current) => (prev.amount > current.amount) ? prev : current);              

        if(maxDiscount.amount && this.totalPrice > maxDiscount.amount){
          this.payload.automaticDiscount["amount"]=maxDiscount.amount;    
          this.payload.automaticDiscount["code"]=maxDiscount.title;         
          this.totalPrice=this.totalPrice-maxDiscount.amount        
          this.payload.automaticDiscount["discount_type"]=maxDiscount.discount_type;  
          this.payload.automaticDiscount["discount_value"]=maxDiscount.discount_value;  
          this.payload.automaticDiscount["minimum_requirements"]=maxDiscount.minimum_requirements; 
          this.payload.automaticDiscount["end_date"]=maxDiscount.end_date; 
          this.payload.automaticDiscount["full_end_date"]=maxDiscount.full_end_date; 
          this.payload.automaticDiscount["full_start_date"]=maxDiscount.full_start_date;          
          this.$forceUpdate();  
        }else{
          this.payload.automaticDiscount["amount"]=0;        
          this.payload.automaticDiscount["code"]="";   
         }  
        }    
    },
    getApplyDiscount(discount){
      let discount_amount=0;   
      let totalQuantity=this.totalQuantity;
        if(this.cartSummary.length > 0){
          totalQuantity=0;
          this.cartSummary.map(async (e) => {      
          totalQuantity = totalQuantity + e.qty;
        });
      }        

      if(discount.minimum_requirements.type=='minimum_purchase'){      
        if(Number(discount.minimum_requirements.value)>this.totalPrice){         
            return {discount_amount}
        }
        
      }else if(discount.minimum_requirements.type=='minimum_quantity'){           
        if(Number(discount.minimum_requirements.value)>totalQuantity){        
            return {discount_amount}
        }  
       
      } 

      if(discount.payment_type=='cod' || this.payload.payment=='COD'){           
        if(this.payload.payment!='COD' && discount.payment_type=='cod'){        
          return {discount_amount}
        }else if(this.payload.payment=='COD' && discount.payment_type=='non_cod'){        
          return {discount_amount}
        }        
      }    
      
      if(discount.discount_type=="moneyOffProduct"){
        let price =  this.price;      
        if(this.cartSummary.length > 0){
          price = 0;
          this.cartSummary.map((e) => {
              let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;             
            }) 
        }    

         
         
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);           
          }else{
            discount_amount =(price *Number(discount.discount_value.value)) / 100;                                      
                           
          }       
          
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
           
            let totalPrice=this.totalPrice
            if(this.payload.automaticDiscount["amount"] && this.payload.automaticDiscount["amount"] > 0){
              totalPrice=this.totalPrice+this.payload.automaticDiscount["amount"];
            }
            discount_amount =(totalPrice *Number(discount.discount_value.value)) / 100;                            
                    
          }  
        }     
        discount_amount=Math.round(discount_amount) 
        return {discount_amount}

    },  
    getImageSrc(attr){ 
      let imageUrl;  
      let variantImageIndex;    
      if(attr && attr.attrs) {
        variantImageIndex = this.data.variablePrices && this.data.variablePrices.findIndex(
          (x) => x.name  && x.image && x.name.indexOf(attr.attrs[0].value+" / ") > -1
        );
      }          
      if(attr.image){       
        imageUrl= getSizedImageUrl(attr.image,'medium','product-images')
      } 
      else if (variantImageIndex > -1 && this.data.variablePrices && this.data.variablePrices[variantImageIndex].image ){       
        imageUrl= getSizedImageUrl(this.data.variablePrices[variantImageIndex].image,'medium','product-images'); 
      } 
      else if (this.productImages.length>0 ){       
        imageUrl= getSizedImageUrl(this.productImages[0].src,'medium','product-images'); 
      }else if(this.variantImageIndex > -1){
        imageUrl= getSizedImageUrl(this.productImages[this.variantImageIndex].image,'medium','product-images'); 
      }            
      return imageUrl;
    },
    getSKU(param){
      for(let i =0; i < param.length;i++){
        if(param[i].SKU && param[i].SKU != ""){
          return param[i];
        }
      }
    },
    isHasOneWordLongText (text, maxLength=10){
      const words = text.split(' ');
      const result = words.some(word => {
          return word.length >= maxLength;
      });
      return result;
    },
    showChoosenData(){
      let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
      let priceVar = this.data.variablePrices.filter(
        (e) => e.name == selectedVariant
      );
      if(priceVar){
        this.chosenData = priceVar[0];
      }
    },
    updateProductImages() {  
      let itemName = [];
      Object.keys(this.payload.variablePrices).map((key) => {
        itemName.push(this.payload.variablePrices[key].name);
      });
      this.variantImageIndex = this.productImages.findIndex(
        (x) => x.name == itemName.join(" / ")
      );
    
      if(this.variantImageIndex==-1){
        this.variantImageIndex = this.productImages.findIndex(
          (x) => x.name && x.name.indexOf(itemName[0]+" / ") > -1
        );
      }
      if(this.variantImageIndex==-1 && this.productImages.length>0){
        this.variantImageIndex =0;
      }
    },
    checkOption(name,attrval){    
     return !this.filterAttributes[name+"_"+attrval]
    },
    async submitted() {
      this.isBuyButtonLoading = true
      this.submited = true;
      this.validate();
      if (Object.keys(this.errors).length) {
        this.$vs.notification({
          color: "danger",
          position: "top-right",
          text: this.$t('validation.pleaseFix'),//"Please fix fields error before proceeding to next step",
          duration: 2000,
        });
        this.isBuyButtonLoading = false
        return;
      }

      if (get(this.data, 'productType') === 'digital' && get(this.data, 'digitalProduct.isBuyerEmailConfirmationEnabled', false)) {
        this.emailConfirmModal = true;
        this.isBuyButtonLoading = false
        return;
      }

      this.save();
    },
    validate() {
      if (!this.submited) {
        return;
      }
      let isActiveMinimumPurchase = false;
      let minimumPurchase = 1;
      this.errors = {};
      let requestedFields = this.data.requestedFields.filter(
        (one) => one.active
      );
      if (this.data.isActiveMinimumPurchase) {
        isActiveMinimumPurchase = true;
        minimumPurchase = this.data.minimumPurchase;
      }
      let isFieldQuantityActive = false;
      const requestedFieldsLength=requestedFields.length;
      requestedFields.map((one,index) => {       
        if ((one.required == "yes" && !one.value) || (this.data.formMode == "Progressive Form" && ((!one.value && one.required == "yes" && requestedFieldsLength== index+1) || (!one.value && requestedFieldsLength!= index+1)))) {
          if (one.field == "phone") {
            this.errors[one.field] = this.$t("validation.mustBeFilledPhone");
          } else if (one.field == "email") {
            this.errors[one.field] = this.$t("validation.mustBeFilledEmail");
          } else if (one.field == "address") {
            if (!this.payload.address) { 
              this.errors[one.field] = this.$t("validation.mustBeFilled");
            } else if (this.payload.address && (!this.payload.address.PROVINCE || !this.payload.address.CITY || !this.payload.address.DISTRICT)) {
              this.errors[one.field] = this.$t("validation.mustBeFilled");
            }
          }else {
            this.errors[one.field] = this.$t("validation.mustBeFilled");
          }
        }
        if (one.field == "phone") {
          let value = one.value.toString();
          value = value.replace(/ /g, "");
          value = value.replace(/-/g, "");
          value = value.replace(/\(/g, "");
          value = value.replace(/\)/g, "");

          var phoneno =
            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          if (!(value ? !!value.match(phoneno) : true)) {
            this.errors[one.field] =  this.$t("validation.mustBeFilledPhone");
          }
        }
        if (one.field == "email" || one.inputType == "email") {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          if (one.value && !re.test(String(one.value).toLowerCase())) {
            this.errors[one.field] = this.$t("validation.mustBeFilledEmail");
          }
        }

        if (one.inputType == "url") {
          var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
          ); // fragment locator
          if (one.value && !pattern.test(one.value)) {
            this.errors[one.field] = "Invalid Value";
          }
        }
        if (one.field == "quantity") {
          if (isActiveMinimumPurchase) {
            if (!one.value) {
              this.errors[one.field] = this.$t("validation.mustBeFilled");
            }
            else if (one.value < minimumPurchase) {
              this.errors[one.field] = this.$t("validation.qtyMin").replaceAll('{x}', minimumPurchase);
              isFieldQuantityActive = true;
            }
          }
        }
      });
           
      // Add required for shipping method
      if(this.data.variationRequierd && this.varData && this.varData.length) {
        this.errors['deliveryCouriePrice'] = this.$t("validation.selectExpedition"); //"Pilih Ekspedisi is required";
      }
      
      if ((this.data.variationRequierd || !isFieldQuantityActive) && this.data && this.data.enableProductOptions && this.data.variablePrices && this.data.variablePrices.length > 0) {
        const allQtyZero = this.data.variablePrices.every(item => item.qty === 0);
        // If all qty values are 0, throw an error
        if (allQtyZero) {
          this.errors["zeroQTY"] = this.$t("validation.qtyGTZero");
        }
        let message = "";
        if (this.data.cartOrder && Cookies.get("cartSummaryCheckout")) {
          let tempCookie = JSON.parse(Cookies.get("cartSummaryCheckout"));
          tempCookie.map((temp) => {
            this.data.variablePrices.map((varPrice) => {
              if (temp.name === varPrice.name) {
                let varPriceIsActiveMinimumPurchase = false;
                let varPriceMinimumPurchase = 1;
                if (varPrice.isActiveMinimumPurchase !== undefined && varPrice.isActiveMinimumPurchase) {
                  varPriceIsActiveMinimumPurchase = varPrice.isActiveMinimumPurchase;
                  varPriceMinimumPurchase = varPrice.minimumPurchase;
                }
                if (varPriceIsActiveMinimumPurchase) {
                  if (varPrice.qty > 0) {
                    if (varPrice.qty < varPriceMinimumPurchase) {
                      let variantAttr = varPrice.attrs[0].name.replaceAll("0", "");
                      message += this.$t("validation.qtyMinVariant").replaceAll("{variantAttr}", variantAttr).replaceAll("{variantName}", varPrice.name).replaceAll('{x}', varPriceMinimumPurchase) + ", ";
                    }
                  }
                }
              }
            });
          });
        }
        else {
          this.data.variablePrices.map((varPrice) => {
            varPrice['zeroQTY']=''
            let varPriceIsActiveMinimumPurchase = false;
            let varPriceMinimumPurchase = 1;
            if (varPrice.isActiveMinimumPurchase !== undefined && varPrice.isActiveMinimumPurchase) {
              varPriceIsActiveMinimumPurchase = varPrice.isActiveMinimumPurchase;
              varPriceMinimumPurchase = varPrice.minimumPurchase;
            }
            if (varPriceIsActiveMinimumPurchase) {
              if (varPrice.qty > 0) {
                if (varPrice.qty < varPriceMinimumPurchase) {
                  let variantAttr = varPrice.attrs[0].name.replaceAll("0", "");
                  if(!(this.data.multiplyVariations && !this.data.cartOrder)){
                    message += this.$t("validation.qtyMinVariant").replaceAll("{variantAttr}", variantAttr).replaceAll("{variantName}", varPrice.name).replaceAll('{x}', varPriceMinimumPurchase) + ", ";
                  }else{
                    this.errors["qtyMinVariant"] = this.$t("validation.qtyMin").replaceAll('{x}', varPriceMinimumPurchase);
                    message += this.$t("validation.qtyMinVariant").replaceAll("{variantAttr}", variantAttr).replaceAll("{variantName}", varPrice.name).replaceAll('{x}', varPriceMinimumPurchase) + ", ";
                  }                
                  varPrice['zeroQTY']=this.$t("validation.qtyMin").replaceAll('{x}', varPriceMinimumPurchase);                
                 
                }
              }
            }
          });
        }
        
        if (message){
          message = message.substring(0, message.length - 2);
          this.errors["zeroQTY"] = message;
        } 
      }

      // Add required for shipping method
      if(this.data.addShipping && this.data.shippingType == 'automated' && this.payload.address && this.courierOptionDataFinal.length > 0 &&  (!this.payload.deliveryCourier || !this.payload.deliveryPrice || !this.payload.deliveryType)) {
        this.errors['deliveryCouriePrice'] = this.$t("validation.selectExpedition"); //"Pilih Ekspedisi is required";
      }
      return;
    },
    displayCopyright(index) {
      if (this.mobileVersion) {
        if (this.compfieldsPositionMobile.length - 1 == index) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    displayDiv(fieldName, divName) {
      if (this.mobileVersion) {
        if (fieldName == divName) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    // captcha settings for checkout page 
    async checkTrackingConversionOnce(order) {
      const compareTime = sessionStorage.getItem("compareTime");
      let orderItem = {}
      if (compareTime && !this.trackConversionApiCalled) {
        const productId = this.data._id
        orderItem = await axios.post(config.api + 'order/trackConversionOnce', { productId: productId, ip: this.data.ip, time: compareTime, product_id: order.product_id, customerName: order.customerName, customerPhone: order.customerPhone, order_id: order._id });
        this.trackConversionApiCalled = true;
        this.trackConversion = orderItem;
      }
      return this.trackConversionApiCalled ? this.trackConversion : orderItem; // Add 'return' statement to return the value of ordersCount
    },
    exit(event, self) {   
      let checkRedirectEnable=true;
      if("enableSettingRedirect" in self.data && self.data.enableSettingRedirect==false){
        checkRedirectEnable=false;
      }     
      if (checkRedirectEnable && self.data.settingRedirect && ((self.data.settingRedirect == 'url' && self.data.settingRedirectUrl !== '') || (self.data.settingRedirect == 'whatsappSeller' && self.data.settingRedirectWAnumber !== '') || (self.data.settingRedirect == 'whatsappSequence' && self.data.settingRedirectWAnumbers.length > 0)) && self.doRedirect) {
        event.preventDefault();
        setTimeout(async function () {
          if (self.data.settingRedirect == 'url') {
            // window.history.pushState("", "", "new url");
            // window.location.reload();
            // location.replace(self.data.settingRedirectUrl);
            window.top.location = self.data.settingRedirectUrl;
          } else {
            let number = self.data.settingRedirectWAnumber
            if (self.data.settingRedirect == 'whatsappSequence') {
              let r = await self.data.settingRedirectWAnumbers.findIndex(e => e.used);
              let index = r != -1 ? (r + 1 != self.data.settingRedirectWAnumbers.length ? r + 1 : 0) : 0
              number = self.data.settingRedirectWAnumbers[index].number;
              self.data.settingRedirectWAnumbers.map(e => { e.used = false });
              self.data.settingRedirectWAnumbers[index].used = true
              if (!self.popExecuted) {
                self.popExecuted = true;
                axios.post(config.api + "product/updateWASequence", {
                  _id: self.data._id,
                  payload: {
                    settingRedirectWAnumbers: self.data.settingRedirectWAnumbers
                  },
                });
              }
            }
            let url = `https://wa.me/${number}`;
            // location.replace(url);
            window.top.location = url;
          }
        }, 0);
        return;
      }
    },
    // async checkLast24HourProduct() {
    //    const productId = this.data._id
    //     let res = await axios.post(config.api + 'order/getLast24HourOrders', {productId: productId, ip: this.data.ip})
    //      if (res.data.success) {
    //         const orderData = res.data.orders
    //         if(orderData.length > 0) {
    //            this.repeatedOrderCount = orderData.length;
    //            this.lastAssignee = orderData[0].handledBy;
    //             if(orderData.length >= 5) {
    //               this.showCaptcha = true
    //             }
    //         } else {
    //            this.lastAssignee = undefined;
    //            this.repeatedOrderCount= 0;
    //            this.showCaptcha = false
    //         } 
    //      } else {
    //            this.lastAssignee = undefined;
    //            this.repeatedOrderCount= 0;
    //            this.showCaptcha = false
    //       }
    // },


    // captcha settings for checkout page 
    async checkCaptchaOnProduct() {
      if (this.data.isCaptchaEnable) {
        const productId = this.data._id
        let res = await axios.post(config.api + 'order/getLast24HourOrders', { productId: productId, ip: this.data.ip, captchaSettings: this.data.captchaSettings })
        const orderData = res.data.orders
        if (res.data.success) {

          //Conditions to check captcha settings and enable captcha
          for (let objKey in this.data.captchaSettings) {
            const element = this.data.captchaSettings[objKey];
            if (element.isEnable) {
              if (element.type == 'setting1' && element.isEnable && element.orderCount <= orderData.setting1) {
                this.showCaptcha = true
              }
              if (element.type == 'setting2' && element.isEnable && element.orderCount <= orderData.setting2) {
                this.showCaptcha = true;
                this.isInvalid = true
              }
              if (element.type == 'setting3' && element.isEnable && element.orderCount <= orderData.setting3) {
                this.showCaptcha = true;
                this.isInvalid = true;
                this.isArchivedOrder = true
              }
            }
          }
        }
      }
    },
    populateCheckout() {
      if (this.checkout_data) {
        let cData = {};
        Object.keys(this.checkout_data).map(key => {
          if (key == 'customerName') {
            cData.name = this.checkout_data[key]
          } else if (key == 'customerPhone') {
            cData.phone = this.checkout_data[key]
          }
          else if (key == 'customerEmail') {
            cData.email = this.checkout_data[key]
          }
          else if (key == 'RECEIVER_ADDR') {
            cData.addressText = this.checkout_data[key]
          } else if (key == 'paymentInfo' && this.data.showPayment) {
            cData.payment = this.checkout_data[key];
            this.payload.payment = this.checkout_data[key];
          } else if (key == 'address') {
            this.payload.address = this.checkout_data[key];
          } else {
            cData[key] = this.checkout_data[key]
          }
        })
        this.data.requestedFields.map((attr) => {
          if (attr.field == 'subdistrict') {
            attr.value = cData[attr.field] ? cData[attr.field] : false
          } else {
            attr.value = cData[attr.field] ? cData[attr.field] : ''
          }
        })
      }
    },
    addCartItem(){
      this.show=!this.show;
    },
    updateCartQuantity(val) {     
      this.cartQuantity = val;      
      if(this.data.type == 'simple'){
        const index=this.data.requestedFields.findIndex(x=>x.field=='quantity' && x.active);       
        if(index > -1){
          this.data.requestedFields[index].value=this.cartQuantity;
        }
      }else if(this.cartSummary.length==0 && this.data.multiplyVariations && this.data.cartOrder ){
        let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
        let priceVar = this.data.variablePrices.filter(
          (e) => e.name == selectedVariant
        );      
        if (priceVar.length) {
          priceVar[0].qty =this.cartQuantity;
        }        
      }else if(!this.data.multiplyVariations && this.data.addQuantity){
        let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
        let priceVar = this.data.variablePrices.filter(
          (e) => e.name == selectedVariant
        );      
        if (priceVar.length) {
          priceVar[0].qty =this.cartQuantity;
        }
      }    

      this.updatePrices();
      this.handleEstimate(true)
    },
    updateCartTotal(val) {
      this.cartTotal = val;
    },
    updateMobileView(data) {
      this.cartSummary = data;
      this.changeVariant = !this.changeVariant;
      let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
      let priceVar = this.data.variablePrices.filter(
        (e) => e.name == selectedVariant
      );
      if (priceVar.length) {
        this.cartQuantity = priceVar[0].qty ? priceVar[0].qty : 1;
      }
      if (this.cartOrderSummary) {
        this.cartOrderSummary = false;
      }
    },
    getSelectedVariantName(param) {
      let selectedVariant = Object.values(param);
      selectedVariant = selectedVariant.map((data) => {
        return data.name;
      });
      return selectedVariant
        .toString()
        .replace(/[,]/g, " / ");
    },
    addToCart(action = undefined) {
      this.show = !this.show;
      this.cartMessage=Object.keys(this.payload.variablePrices)
                    .map((o) => this.payload.variablePrices[o].name)
                    .join(" - ");
      let temp = [];
      if (Cookies.get("cartSummaryCheckout")) {
        temp = JSON.parse(Cookies.get("cartSummaryCheckout"));
      }
      if (temp.length == 0) { this.data.variablePrices.map(e => e.qty = 0) }
      let selectedVariant = this.getSelectedVariantName(this.payload.variablePrices)
      let priceVar = this.data.variablePrices.filter(
        (e) => e.name == selectedVariant
      );
      if (action == "update" || this.data.cartOrder) {
        if (priceVar.length) {
          priceVar[0].qty = this.data.addQuantity ? this.cartQuantity : (this.data.cartOrder && this.data.addQuantity) ? priceVar[0].qty + 1 : this.cartQuantity;
        }
      }
      let presentIndex = temp.findIndex(e => e.name == selectedVariant)
      if (presentIndex != -1) {
        // temp[presentIndex].quantity = temp[presentIndex].quantity + (priceVar.length ? priceVar[0].qty : 1);
        temp[presentIndex].qty = (priceVar.length ? priceVar[0].qty : 1);

      } else {
        temp.push({
          name: selectedVariant,
          items: this.payload.variablePrices,
          priceOriginal: priceVar.length
            ? priceVar[0].price
              ? priceVar[0].price
              : 0
            : 0,
          price: priceVar.length
            ? priceVar[0].priceCompare
              ? priceVar[0].priceCompare
              : 0
            : 0,
          whosaleData: priceVar.length && priceVar[0].whosaleData ? priceVar[0].whosaleData : undefined,
          qty: priceVar.length ? priceVar[0].qty : 1,
          weight: priceVar.length ? priceVar[0].weight : this.data.weight,
          image: priceVar.length ? priceVar[0].image : "",
          isActiveMinimumPurchase: priceVar[0].isActiveMinimumPurchase,
          minimumPurchase: priceVar[0].minimumPurchase,
        });
      }
      Cookies.set("cartSummaryCheckout", JSON.stringify(temp), {
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
      });      
      if(this.data.multiplyVariations &&  this.data.cartOrder){
        this.cartSummary = JSON.parse(Cookies.get("cartSummaryCheckout"));
      }

    
      let self = this
      this.updatePrices();   

      setTimeout(() => {      
        self.$forceUpdate();
      }, 2000)
    },
    openCartSummary() {
      this.cartOrderSummary = true;
      if (this.changeVariant) {
        this.changeVariant = false;
      }
    },
    showMsg() {
      var that = this;
      setTimeout(function () {
        that.show = false;
      }, 2000); // hide the message after 2 seconds
    },
    removeFromCart(index) {
      if (this.cartSummary.length) {
        this.updateCartSummary(index)
      }
    },
    updateCartSummary(index) {
      let name = this.cartSummary[index].name;
      this.cartSummary.splice(index, 1);
      Cookies.set("cartSummaryCheckout", JSON.stringify(this.cartSummary), {
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
      });
      let f = this.data.variablePrices.filter(e => e.name == name)
      if (f.length) { f[0].qty = 0 }
      this.updatePrices()
      this.$forceUpdate();
    },
    increment(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.data.variablePrices[val]) {
          this.data.variablePrices[val].qty =
            parseInt(this.data.variablePrices[val].qty) + 1;
        }
      } else if (type == "singleVariant") {
        this.cartQuantity = this.cartQuantity + 1;
      }
    },
    decrement(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.data.variablePrices[val].qty &&
          this.data.variablePrices[val].qty > 0
        ) {
          if (this.data.variablePrices[val].qty < 1) {
            return;
          } else {
            this.data.variablePrices[val].qty =
              this.data.variablePrices[val].qty - 1;
          }
        }
      } else if (type == "singleVariant") {
        if (this.cartQuantity < 2) {
          return;
        } else {
          this.cartQuantity = this.cartQuantity - 1;
        }
      }
    },

    applyVATPercentage() {
      if(this.data && this.data.VAT && this.data.VAT.isVAT) {
        let PPNprice = 0;
        PPNprice = this.cartSummary.length > 0 && this.cartTotal > 0 ? (this.cartTotal * this.data.VAT['VATpercentage'])/100 : (this.totalPrice * this.data.VAT.VATpercentage)/100
        this.VATPrice = Math.round(PPNprice);
        this.totalPrice = this.totalPrice + this.VATPrice;
      }
    },
    resetPrices() {   
      if(this.payload.automaticDiscount){
        this.payload.automaticDiscount["amount"]=0      
      }
      if(this.payload.discount){
        this.payload.discount["amount"]=0    
      } 
      this.calcPrice();
      this.calcPriceWithoutWhosale();
      this.calcTotalPrice();       
      if(this.data.showCoupon) {
        this.applyAutmaticDiscount();      
      }   
    },
    updatePrices() {    
      if(this.payload.automaticDiscount){
        this.payload.automaticDiscount["amount"]=0      
      }
      if(this.payload.discount){
        this.payload.discount["amount"]=0    
      }
         
      this.calcPrice();
      this.calcPriceWithoutWhosale();
      this.calcTotalPrice();
      this.isShowVariantImage=false;
      this.data.variablePrices.map(v=>{
        if(!this.isShowVariantImage && v.image !== undefined){
            this.isShowVariantImage=true;
        }         
      }); 
      if(this.data.showCoupon && this.payload.automaticDiscount) {
        this.applyAutmaticDiscount();      
      }   
      if(this.payload.discount){
       this.applyDiscount(this.payload.discount.code); 
      }
      if(this.data && this.data.VAT && this.data.VAT.isVAT) {
        this.applyVATPercentage()
      }
      this.$forceUpdate();
    },

    loadVariableArray() {
      if (this.cartSummary.length != 0) {

        return this.data.variablePrices.map((variable) => {
          const variableIndex = this.cartSummary.findIndex((data) => data.name == variable.name)
          if (variableIndex == -1) {
            return {
              ...variable,
              qty: 0
            }
          } else {
            return { ...variable }
          }

        })
      }
      return this.data.variablePrices
    },

    async createData() {
      let payload = {}
      let customFields = []
      let keys = Object.keys(this.payload.variablePrices);
      let dataVal = keys.map(o => this.payload.variablePrices[o].name).join(' / ')
      this.data.requestedFields.map(one => {
        if (one.isCustom) {
          // customFields.push({ [one.label]: one.value })
         customFields.push({label: one.label,value:one.value })
        } else {
          payload[one.field] = one.value
        }
      })
      payload.bumpArray = this.data.isBump && this.selectedbumpArray ? this.selectedbumpArray : [];
      // payload.allBumpData = this.data.isBump && this.data.bumpArray ? this.data.bumpArray : [];
      payload.isBump = this.data.isBump
      payload.checkout_id = this.checkout_id ? this.checkout_id : undefined;
      payload.handledBy = this.belongs_to
      payload.dropshipper = this.data.dropship
      payload.payment = this.payload.payment
      const type = this.data.paymentType.filter((p) => p.active).find(x=>x.type==this.payload.payment)
      payload.paymentMode = this.data.epaymentSettings.findIndex(x=>x.id==this.payload.payment) > -1 ? 'E-Payment' : type && type.type ?  type.type : this.payload.paymentMode
      payload.ePaymentFeePaidBy = this.data.ePaymentFeePaidBy;
      payload.variations = this.data.type !== "simple" && this.data.enableProductOptions ? this.loadVariableArray() : []
      payload.product_id = this.data._id
      payload.address = this.payload.address
      payload.quantity = this.quantity
      payload.form_id = this.data.form_id
      payload.productName = this.data.name
      payload.customFields = customFields
      payload.grossRevenue = this.cartSummary.length > 0 && this.cartTotal > 0 ? Math.round(this.cartTotal +  (this.data.VAT && this.data.VAT.isVAT ? ((this.cartTotal * this.data.VAT['VATpercentage'])/100) : 0)) : this.totalPrice
      payload.checkoutPageName = this.data.pageName
      payload.productPrice = this.price
      payload.costPerItem = this.data.costPerItem
      payload.productPriceWithoutDiscount = this.data.type == 'simple' ? (this.data.price * this.quantity) : this.priceWithoutWhosale
      payload.cogsPrice = this.cogsPrice
      payload.shippingType = this.payload.payment === 'COD' ? this.data.shippingCOD.shippingType : this.data.shippingType
      payload.shippingPrice = this.deliveryPrice
      payload.shippingType = this.data.addShipping ? this.data.shippingType : ''
      payload.codPrice = this.payload.payment == 'COD' ? this.codPrice : 0
      payload.uniquePrice = (this.data.codeType == 'Decrease' ? -this.payload.uniquePrice : this.payload.uniquePrice)    
      payload.hideAdditionalCost = (this.payload.payment == 'COD' ? this.data.shippingCOD.hideAdditionalCost : this.data.hideAdditionalCost) || false
      payload.additionalCost = this.data.addShipping ? parseInt(this.payload.payment === 'COD' ? this.data.shippingCOD.additionalCost : this.data.additionalCost) : 0
      function getParameterByName(name) {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
      }
      payload.UTMSource = getParameterByName('utm_source')
      payload.UTMMedium = getParameterByName('utm_medium')
      payload.UTMTerm = getParameterByName('utm_term')
      payload.UTMContent = getParameterByName('utm_content')
      payload.UTMCampaign = getParameterByName('utm_campaign')

      payload.multipleShipFrom=this.data.multipleShipFrom
      payload.multiShipFromAddress=this.data.multiShipFromAddress

      // let ip;
      // if(localStorage.getItem('ip')) {
      //   ip = localStorage.getItem('ip');
      // }else{
      //   let lp = await axios.get('https://api.ipify.org/?format=json');
      //   localStorage.setItem('ip', lp.data.ip)
      //   ip = lp.data.ip;
      // }
      payload.metaData = {
        domain: this.domain,
        ip: this.data.ip,
        url: window.location.href
      }
      payload.ip = this.data.ip
      payload.affiliateId = this.affiliateId ? this.affiliateId : undefined;
      payload.ref = this.refid ? this.refid : undefined;    

      if(this.payload.sellerOriginId){       
        let sellerAdd = this.data.multiShipFromAddress[this.payload.sellerOriginId]
        Object.keys(this.data.multiShipFromAddress).map(id=>{
         if (this.data.multiShipFromAddress[id].address && this.data.multiShipFromAddress[id].address.PICKUP_AUTOFILL==this.payload.sellerOriginId){
          sellerAdd=this.data.multiShipFromAddress[id]
         }         
        })
        if(sellerAdd && sellerAdd.PROVINCE_NAME){
          let add =  sellerAdd.PROVINCE_NAME + ', ' + sellerAdd.CITY_NAME + ', ' + sellerAdd.DISTRICT_NAME
          payload.sellerAddress = add;
        }   
        
        sellerAdd = this.data.multiShipFromAddress[this.payload.sellerOriginId]
        if(sellerAdd && sellerAdd.PROVINCE_NAME){
          let add =  sellerAdd.PROVINCE_NAME + ', ' + sellerAdd.CITY_NAME + ', ' + sellerAdd.DISTRICT_NAME
          payload.sellerAddress = add;
        }  
        
        if(!payload.sellerAddress){
          Object.keys(this.data.multiShipFromAddress).map(id=>{
            if (this.data.multiShipFromAddress[id] && this.data.multiShipFromAddress[id].PICKUP_AUTOFILL==this.payload.sellerOriginId){
              sellerAdd=this.data.multiShipFromAddress[id]
            }         
          })
          if(sellerAdd && sellerAdd.PROVINCE_NAME){
            let add =  sellerAdd.PROVINCE_NAME + ', ' + sellerAdd.CITY_NAME + ', ' + sellerAdd.DISTRICT_NAME
            payload.sellerAddress = add;
          }   
        }   
        
        payload.sellerAddressPickupAutofill = this.payload.sellerOriginId;       
      }  

      if (this.payload.deliveryPrice) {
        payload.shippingInfo = {
          deliveryType: this.payload.deliveryType,
          deliveryPrice: this.payload.deliveryPrice,
          deliveryCourier: this.payload.deliveryCourier,
          shippingDiscountValue: this.payload.shippingDiscountValue,
        }
      }

      if (this.data && this.data.isBump && this.selectedbumpArray && this.selectedbumpArray.length >= 0) {
        // price = price + parseFloat(this.data.bumpPrice)
        let totalBumpPrice = 0
        this.selectedbumpArray.map((bumpData) => {
          // const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            totalBumpPrice = totalBumpPrice + parseFloat(bumpData.bumpPrice)
          }
        })
        payload.bumpPrice = totalBumpPrice
      }

      // if (this.payload.bump) {
      //   payload.bumpPrice = this.data.bumpPrice 
      // }
      if (this.data.type !== "simple" && !this.data.multiplyVariations && !this.data.addQuantity && (this.data.requestedFields.find(d => d.field == 'quantity') && this.data.requestedFields.find(d => d.field == 'quantity').active)) {
        payload.variations.map((attr) => {
          if (attr.name == dataVal) {
            attr.qty = this.quantity
          }
        })
      }

      if(this.data.VAT && this.data.VAT.isVAT) {
        payload.VAT = { 
          isVAT : this.data.VAT['isVAT'],
          value : this.data.VAT['VATpercentage'], 
          amount : this.VATPrice
        }
      }

      if (this.inputEmailConfirm && this.inputEmailConfirm.length) {
        payload.email = this.inputEmailConfirm;
      }
      
      payload.discount=this.payload.discount;    

      payload.automaticDiscount=this.payload.automaticDiscount;

      return payload;
    },
    async save() {
      try {
        if (!this.data.enableProductOptions && this.data.trackQuantity && this.data.available < 1 && !this.data.continueSelling) {
          return
        }
        // this.loadingPrice = true;
        let payload = await this.createData();
        if (this.data.preOrder) {
          payload.preOrder = this.data.preOrder
        }

        if (this.lastAssignee) {
          payload.handledBy = this.lastAssignee
        }

        if (this.isArchivedOrder) {
          payload.isArchived = true
        }
        let {fbp, fbc} = this.getfbpc();

        payload.fbp = fbp
        payload.fbc = fbc        

        const hmac = createHmac('sha256', secret);
        hmac.update(JSON.stringify(payload));
        const signature = hmac.digest('hex');
      
        let res = await axios.post(config.api + 'order', {data:payload,signature})
        if (res.data.success) {
          sessionStorage.removeItem('affiliateId');
          this.doRedirect = false;
          
          // await this.createScripts(res.data.data);
          if (res.data.isDuplicateOrder) {
            Cookies.remove("cartSummaryCheckout");
            Cookies.remove("tuktukSummaryCheckout");
            this.orderCreated = true;
            // if (!window.location.host.includes('form-id') && !window.location.host.includes('form.id') && !window.location.host.includes('localhost')) {
            //   const redirectUrl = config.baseUrl.replace('dist', '')
            //   let url = redirectUrl + 'success/' + this.data.url + '?order=' + res.data.data._id;
            //   window.location.href = url
            //   return
            // }
            this.doRedirectAfterOrder(res);

            return
          }
          Cookies.remove("cartSummaryCheckout");
          Cookies.remove("tuktukSummaryCheckout");
          let timeout = 0;
          if(this.data.pixelsGoogleAds && this.data.pixelsGoogleAds.length > 0 || this.data.pixelsFB && this.data.pixelsFB.length > 0 || this.data.GTM && this.data.GTM.length > 0 || this.data.pixelsTiktok){
            timeout= 700
          }
          setTimeout(() => {
            if (res.data.transaction) {
              // let url = this.baseUrl.split('://')[0] + '://' + this.baseUrl.split('://')[1] + 'ePayment?uid=' + res.data.transaction.token + '&domain=' + this.domain;

              let url = this.baseUrl.split('://')[0] + '://' + this.baseUrl.split('://')[1] + 'ePayment?uid=' + res.data.transaction.token + '&domain=' + this.domain + '&product_url=' + this.data.url;

              window.location.href = url
            } else {
              this.orderCreated = true;

              // if (!window.location.host.includes('form-id') && !window.location.host.includes('form.id') && !window.location.host.includes('localhost')) {
                //   const redirectUrl = config.baseUrl.replace('dist', '')
              //   let url = redirectUrl + 'success/' + this.data.url + '?order=' + res.data.data._id;
              //   window.location.href = url
              //   return
              // }
              this.doRedirectAfterOrder(res);
            }
          }, timeout)
        } else {
          // we do not need to clear bump product.
          // if(res.data.message.includes("Bump product is Out of stock")){
          //   this.data.isBump = false
          //   this.selectedbumpArray =[]
          // }
          this.$vs.notification({
            color: "danger",
            position: "top-right",
            text: res.data.message,
            duration: 2000,
          });
          return;
        }
       
      } catch (error) {
        this.$vs.notification({
          color: "danger",
          position: "top-right",
          text: error && error.message ? error.message : "Error creating order",
          duration: 2000,
        });
        return;
      } finally {
        this.isBuyButtonLoading = false;
        this.inputEmailConfirm = '';
      }
    },
    doRedirectAfterOrder(res){
      let {fbp, fbc} = this.getfbpc();
      let url = window.location.protocol + "//" + this.domain + '/success/' + this.data.url + '?order=' + res.data.data._id +`&fbp=${fbp}&fbc=${fbc}`;
      window.location.href = url
    },
    async estimate(force = false) {
      const shippingType = this.payload.payment === 'COD' ? this.data.shippingCOD.shippingType : this.data.shippingType;
      let val = this.payload.address
      if (val && this.data.addShipping && shippingType == 'automated') {
        if (this.DESTINATION_CODE !== val.DESTINATION_CODE || force) {
          this.DESTINATION_CODE = val.DESTINATION_CODE
          let weight = this.weight
          weight = weight / 1000
          weight = Math.ceil(weight);
          // let origin = this.data.shipFrom.split('-').length > 0 ? this.data.shipFrom.split('-')[0] : this.data.shipFrom

          this.loadingPrice = true
          let destination_to = val.customerAUTOFILL
          this.payload.deliveryType = '';
          this.payload.deliveryPrice = 0;
          this.payload.deliveryCourier = '';

          // Shipping estimate API
          if(this.data.multipleShipFrom.length > 0 && this.data.multiShipFromAddress) {
            let courierVal = [];
            this.shippingCourierTypes.map((key)=> {           
              if(this.data[key] == true) {
                courierVal.push(key);
              } else {
                return ;
              }
            })
            let deliveryCopy={};  
            this.shippingCourierTypes.forEach(e=>{
              deliveryCopy[e] = [];
            });

            await Promise.all(this.data.multipleShipFrom.map(async(item)=>{
              let splitshipfrom = item.split('-');
              let origin_id = splitshipfrom.length > 2 ? splitshipfrom[splitshipfrom.length-1] : splitshipfrom[0];
              let address=this.data.multiShipFromAddress[origin_id]
              if(this.data.multiShipFromAddress && this.data.multiShipFromAddress[origin_id] && this.data.multiShipFromAddress[origin_id].address){
                address=this.data.multiShipFromAddress[origin_id]

                origin_id=this.data.multiShipFromAddress[origin_id].address.PICKUP_AUTOFILL
              }
              let res = await axios.get(config.shippingUrl + 'order/allEstimatePublic', { params: { username: this.user.username, origin_id: origin_id, destination_id: destination_to, weight: weight } })
          
              if (res.data.success && res.data.data) {          
                courierVal.map((courier)=>{          
                  
                  const courierFormData= (courier.toLowerCase() == 'sicepat') ? 'SiCepat' : (courier== 'jNt') ? 'JT' : (courier== 'jne') ? 'JNE' : (courier== 'sap') ? 'SAP'  : (courier== 'ninja') ? 'Ninja' :  courier;   
                          
                    if (res.data.success && res.data.data && res.data.data[courierFormData]) {
                      res.data.data[courierFormData]['address'] =address
                      res.data.data[courierFormData]['origin_id']= origin_id
                      if(res.data.data[courierFormData]['price'] > 0) {                
                        deliveryCopy[courier].push(res.data.data[courierFormData]);
                      }
                    }
                })
              }
            }))
            // Sort the prices in increasing order for cost-effective
            this.courierOptionData.map(c=>{          
              if (Array.isArray(deliveryCopy[c.value]) && deliveryCopy[c.value].length > 0) {
                deliveryCopy[c.value] = deliveryCopy[c.value].sort((a, b) => a.price - b.price);
                this.delivery[c.value] = [deliveryCopy[c.value][0]]
              }   
            })
          } else if(this.data.shipFrom) {
            let splitshipfrom = this.data.shipFrom.split('-');
            let origin_id = splitshipfrom.length > 2 ? this.data.shipFrom.split('-')[splitshipfrom.length-1] : this.data.shipFrom[0];

            let delivery={};
            // let shippingCourierTypes=["JNE",'JT','Ninja','SAP','SiCepat','SiCepatCargo','iDexpress','iDlite'];
            this.shippingCourierTypes.forEach(e=>{
              delivery[e] = [];
            });
            let courierVal = [];
            this.shippingCourierTypes.map((key)=> {           
              if(this.data[key] == true){
                courierVal.push(key);
              }else{
                return ;
              }
            })
            let res = await axios.get(config.shippingUrl + 'order/allEstimatePublic', { params: { username: this.user.username, origin_id: origin_id, destination_id: destination_to, weight: weight } })
          
            if (res.data.success && res.data.data) {          
              courierVal.map((courier)=>{          
    
                // const courierParam= (courier.toLowerCase() == 'sicepat') ? 'siCepat' : (courier== 'sap') ? 'Sap':  courier;   
                const courierFormData= (courier.toLowerCase() == 'sicepat') ? 'SiCepat' : (courier== 'jNt') ? 'JT' : (courier== 'jne') ? 'JNE' : (courier== 'sap') ? 'SAP'  : (courier== 'ninja') ? 'Ninja' :  courier;   
                        
                  if (res.data.success && res.data.data && res.data.data[courierFormData]) {
                    delivery[courier] = [res.data.data[courierFormData]];
                    delivery[courier].push({});
                    delivery[courier].pop();
                  }
              })
            }
          
            this.delivery={...delivery}; 
          
          }

          // common for old and new product
          this.courierOptionDataFinal=[];
          this.courierOptionData.map(c=>{   
            if(this.delivery[c.value] && this.delivery[c.value].length>0){
              this.delivery[c.value].map(p=>{   
                if(p && p.price) {
                  this.courierOptionDataFinal=[...this.courierOptionDataFinal,{
                    value:c.value+"=="+p.price,
                    label:c.label+" - "+(p.type?p.type:'REG')+" - Rp"+ Vue.filter('g_number_format')(p.price),
                    label2:c.label2+" - "+(p.type?p.type:'REG')+" - Rp"+Vue.filter('g_number_format')(p.price),
                    key:c.value,
                    shipFromAddress:p.address,
                    origin_id:p.origin_id
                  }];  
                }
              });

              // Sort the courierOptionDataFinal array based on the this.data.courierType
              if(this.data.courierType) {
                const orderArray = this.data.courierType
                const courierKeyMap = {};
                orderArray.forEach((key, index) => {
                  courierKeyMap[key.toLowerCase()] = index;
                });
      
                this.courierOptionDataFinal.sort((a, b) => {
                  const courierA = a.value.split('==')[0].toLowerCase();
                  const courierB = b.value.split('==')[0].toLowerCase();
                  return courierKeyMap[courierA] - courierKeyMap[courierB];
                });
              }

              let price=this.courierOptionDataFinal[0].value;
              price=price.split("==");
              this.payload.deliveryCourier=price[0];
              this.payload.deliveryPrice=price[1];              
              let priceLabel=this.courierOptionDataFinal[0];   
              priceLabel=priceLabel.label.split("-");               
              this.payload.deliveryType=priceLabel[1].trim() ? priceLabel[1].trim() : 'REG';    
              this.deliveryCourierPrice =this.payload.deliveryCourier + "==" + this.payload.deliveryPrice;
            }                
          }) 
          this.loadingPrice = false
        }
      }
    },
    initiateScript() {
      if (this.data.GTA && this.data.GTALabel) {
        //eslint-disable-next-line
        this.scripts.push({ script: "<script defer src='https://www.googletagmanager.com/gtag/js?id=AW-" + this.data.GTA + "'><\/script>", name: "GTA", type: "GTA" });
        //eslint-disable-next-line
        this.scripts.push({ script: "<script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', 'AW-" + this.data.GTA + "');<\/script>", name: "GTA", type: "GTA" });
      }
    },
    checkSession(param){
      if(sessionStorage.getItem(param)){
        return true
      }
      if (!sessionStorage.getItem(param)) {
        sessionStorage.setItem(param, true);
      }
      return false;
    },
    async createScripts() {
      /** 
       * jeslin- 04 august 2023 commenting out invalid var to enable tracking on pageload and no check for fake order
       */
      // if (this.isInvalid) {
      //   return
      // } else {
        //For GTA tracking
        if (this.data.GTA && this.data.GTALabel) {
          let process = true;
          // if user select global conversion settings 
          if (this.data.GTAtrackingConversion == 'global') {
            this.data.GTAtrackingConversion = this.data.globalConversionSetting
          }
          if (this.data.GTAtrackingConversion == 'once') {
            const result = await this.checkSession("GTAtrackingConversion");
            if (result) {
              process = false
            }
          }
          if (process) {
            //eslint-disable-next-line
            this.scripts.push({ script: "<script>gtag('event', 'conversion', {'send_to': 'AW-" + this.data.GTA.trim() + "/" + this.data.GTALabel + "'});<\/script>", name: "GTA", type: "GTA" });
          }
        }

        //For fb tracking
        this.total_price = this.totalPrice
        this.product_name = this.data.name
        this.product_category_full = this.data.category + this.data.category2 + this.data.category3
        if (this.data.pixelsFB && this.data.pixelsFB.length > 0 && (this.data.nuxt2 || (!this.data.nuxt2 && this.setting.isFbPixel=='true'))) {
          //eslint-disable-next-line
          let str ="";
          if(!this.data.nuxt2){
            //eslint-disable-next-line
            str = "<script>!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};" + "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];" + "s.parentNode.insertBefore(t,s)}(window, document,'\script','https://connect.facebook.net/en_US/fbevents.js');"
          }
          await Promise.all(this.data.pixelsFB.map(async pixel => {
                  let process = true
                  // if user select global conversion settings 
                  if (pixel.trackingConversion == 'global') {
                    pixel.trackingConversion = this.data.globalConversionSetting
                  }
                  if (pixel.trackingConversion == 'once') {
                    const result = await this.checkSession("pixelsFB_"+pixel.pixelId);
                    if (result) {
                      process = false
                    }
                  }    
                  
                  if(process){
                    if(this.data.nuxt2){
                        initPixel(pixel.pixelId.trim())                        
                        pixel.pixelEvents.forEach(events => {                         
                          if(events.name!=''){
                            var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item.name !== "currency" ? this[item.data.code] : item.data.code }), {});
                            trackEventSingle(pixel.pixelId.trim(), events.name,JSON.stringify(object));
                          }
                        })   
                      }else{
                        str += `fbq('init', "${pixel.pixelId.trim()}");`;
                        if (pixel.pixelEvents && pixel.pixelEvents.length > 0) {
                                  pixel.pixelEvents.forEach(events => {
                                    if(events.name){
                                      var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: item.name !== "currency" ? this[item.data.code] : item.data.code }), {});
                                      str += `fbq('trackSingle', "${pixel.pixelId.trim()}","${events.name}",${JSON.stringify(object)}, ${JSON.stringify({eventID: events.name.toLowerCase()+"."+Math.floor(new Date() / 1000)})});`;
                                    }                
                                    
                                  });
                                }
                      }
               
                } 
              }))
              if(!this.data.nuxt2){
                //eslint-disable-next-line
                str += "<\/script>";
                this.scripts.push({ script: str, name: "fb", type: "fb" });
              }                  
        }
        if (this.data.GTM && this.data.GTM.length > 0 && (this.data.nuxt2 || (!this.data.nuxt2 && this.setting.isGtm=='true'))) {
          this.data.GTM.forEach(async gtm => {
            // if user select global conversion settings 
            if (gtm.trackingConversion == 'global') {
              gtm.trackingConversion = this.data.globalConversionSetting
            }
            if (gtm.trackingConversion == 'once') {
              const result = await this.checkSession("GTMTracking_"+gtm.name);
              if (result) {
                return;
              }
            }
            //eslint-disable-next-line
            // this.scripts.push({ script: "<script>(function(w,d,s,l,i){w[l]=w[l]||[];" + "w[l].push({'formLocation': 'head','gtm.start':new Date().getTime(),event:'gtm.js'})" + ";var f=d.getElementsByTagName(s)" + "[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?" + "id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtm.name + "');<\/script>", name: gtm.name, type: "gtm" });
            //eslint-disable-next-line
            let recaptchaScript = document.createElement('script')
            let str = "(function(w,d,s,l,i){w[l]=w[l]||[];" + "w[l].push({'formLocation': 'head','gtm.start':new Date().getTime(),event:'gtm.js'})" + ";var f=d.getElementsByTagName(s)" + "[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?" + "id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','" + gtm.name + "');"
            recaptchaScript.innerHTML = str;
            document.head.appendChild(recaptchaScript)
            let recaptchaScript1 = document.createElement('noscript')
            let str1 = `<iframe  :src="'https://www.googletagmanager.com/ns.html?id=' + ${gtm.name}" height="0"
          width="0" style="display:none;visibility:hidden"></iframe>`;
            recaptchaScript1.innerHTML = str1;
            document.body.appendChild(recaptchaScript1)
          });
        }
      
        if (this.data.pixelsTiktok && this.data.pixelsTiktok.length > 0) {         
          await Promise.all(this.data.pixelsTiktok.map(async pixelsTiktok => {
             let pixel = pixelsTiktok ? pixelsTiktok.pixelId : ''
            // if user select global conversion settings 
            if (pixelsTiktok.trackingConversion == 'global') {
              pixelsTiktok.trackingConversion = this.data.globalConversionSetting
            }
            if (pixelsTiktok.trackingConversion == 'once') {
              const result = await this.checkSession("pixelsTiktok_"+pixel);
              if (result) {
                return
              }
            }
           
           
            let recaptchaScript = document.createElement('script')
            let str =""
            if(!this.data.nuxt2){
               //eslint-disable-next-line
              str = "!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=['page','track','identify','instances','debug','on','off','once','ready','alias','group','enableCookie','disableCookie'],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i='https://analytics.tiktok.com/i18n/pixel/events.js';ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement('script');o.type='text/javascript',o.async=!0,o.src=i+'?sdkid='+e+'&lib='+t;var a=document.getElementsByTagName('script')[0];a.parentNode.insertBefore(o,a)}; ttq.load('" + pixel + "'); ttq.page(); }(window, document, 'ttq');";  
            }
                 
           initTiktokPixel(pixel)
            if (pixelsTiktok.pixelEvents && pixelsTiktok.pixelEvents.length > 0 && pixelsTiktok.enablePixelEvent) {
              pixelsTiktok.pixelEvents.forEach(events => {              
                if(events.name){                
                  if(events.settings){
                    // var object = events.settings.reduce((obj, item) => Object.assign(obj, { [item.name]: ((item.name !== "currency" || item.name !== "content_type") ? this[item.data.code] : item.data.code) }), {});
                    var object = {}; 
                    events.settings.map((item) => {
                      if(item.name == "currency" || item.name == "content_type"){
                        object[item.name] = item.name == "currency" ? "IDR" : "product"
                      }else{
                        object[item.name] = item.name == "content_id" ? this.getContentId(this.data, this.getSKU(this.data.variablePrices), item.data.code) : this[item.data.code]
                      }
                    });
                    if(this.data.nuxt2){
                      trackEventSingleTiktok(pixel,events.name,object)
                    }else{
                      str += `ttq.track("${events.name}",${JSON.stringify(object)});`; 
                    }
                   
                   //                 
                  }else{
                    if(this.data.nuxt2){
                      trackEventSingleTiktok(pixel,events.name)      
                    }else{
                      str += `ttq.track("${events.name}");`;
                    }                    
                        
                  }
               }
              });
            }
            if(!this.data.nuxt2){
              recaptchaScript.innerHTML = str;
            document.head.appendChild(recaptchaScript)
            }
          }));
        }

        if(this.data.pixelsGoogleAds && this.data.pixelsGoogleAds.length > 0){
                this.data.pixelsGoogleAds.forEach(async gta => {
                        // if user select global conversion settings 
                if (gta.trackingConversion == 'global') {
                    gta.trackingConversion = this.data.globalConversionSetting
                }
                if (gta.trackingConversion == 'once') {
                    const result = await this.checkSession("pixelsGoogleAds_"+gta.pixelId);
                    if (result) {
                        return
                    }
                    
                }
                //eslint-disable-next-line
                this.scripts.push({script: "<script defer src='https://www.googletagmanager.com/gtag/js?id=AW-"+gta.pixelId+"'><\/script>", name: "GTA", type: "GTA"});
                //eslint-disable-next-line
                this.scripts.push({script: "<script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', 'AW-"+gta.pixelId+"');<\/script>", name: "GTA", type: "GTA"});
                //eslint-disable-next-line
                // this.scripts.push({script: "<script>gtag('event', 'conversion', {'send_to': 'AW-"+gta.pixelId+"/"+gta.name+"', 'transaction_id': '"+this.order._id+"', 'value': '"+this.total_price+"', 'currency': 'USD'});<\/script>", name: "GTA", type: "GTA"});
                });

            }

        //  if(this.data.pixelsGoogleAds && this.data.pixelsGoogleAds.length > 0){
        //     this.data.pixelsGoogleAds.forEach(async gta => {
        //           // if user select global conversion settings 
        //     if (gta.trackingConversion == 'global') {
        //       gta.trackingConversion = this.data.globalConversionSetting
        //     }
        //     if (gta.trackingConversion == 'once') {
        //         const result = await this.checkTrackingConversionOnce(this.order);
        //         if (result.data && result.data.length > 0) {
        //             return
        //         }
        //         const storedData = localStorage.getItem("orderData");
        //         if (storedData) {
        //         const storedOrder = JSON.parse(storedData);
        //         if (
        //             storedOrder.customerName === this.order.customerName &&
        //             storedOrder.ORDER_ID === this.order.ORDER_ID &&
        //             storedOrder.product_id === this.order.product_id &&
        //             storedOrder.customerPhone === this.order.customerPhone
        //         ) {
        //             return;
        //         }
        //         }
        //     }
        //     //eslint-disable-next-line
        //     this.scripts.push({script: "<script defer src='https://www.googletagmanager.com/gtag/js?id=AW-"+gta.pixelId+"'><\/script>", name: "GTA", type: "GTA"});
        //     //eslint-disable-next-line
        //     this.scripts.push({script: "<script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', 'AW-"+gta.pixelId+"');<\/script>", name: "GTA", type: "GTA"});
        //     //eslint-disable-next-line
        //     this.scripts.push({script: "<script>gtag('event', 'conversion', {'send_to': 'AW-"+gta.pixelId+"/"+gta.name+"', 'transaction_id': '"+order_id+"', 'value': '"+this.total_price+"', 'currency': 'USD'});<\/script>", name: "GTA", type: "GTA"});
        //     });
     
        // }
        setTimeout(() => {
          return true;
        }, 1000)
      // }
    },
    async createAbandon() {
      if (!this.orderCreated) {
        let payload = await this.createData();
        if (payload && payload.phone) {
          if (this.data.digitalProduct !== undefined) {
            payload.productType = 'digital'
          } else {
            payload.productType = 'physical'
          }
          payload.productUrl = this.data.url;
          let url = this.checkout_id ? config.api + 'order/checkout/update' : config.api + 'order/checkout';
          let apiData = this.checkout_id ? { payload, checkout_id: this.checkout_id } : payload
          let e = await axios.post(url, apiData);
          if (e.data && e.data.data && e.data.data._id && !this.checkout_id) {
            this.checkout_id = e.data.data.checkout_id;
            this.id = e.data.data._id;
          }
        }
      }
    },
    calcPrice() {
      let price = 0;
      this.totalQuantity=0;
      let keys = Object.keys(this.payload.variablePrices);
      let dataVal = this.payload.variablePrices
        ? keys.map(o => this.payload.variablePrices[o].name).join(' / ')
        : undefined;

       if(this.cartSummary.length > 0) {
        this.cartSummary.map(async (e) => {
          let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
          if (e.whosaleData) {
            e.whosaleData.map((one) => {
              if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                subPrice = one.price * parseInt(e.qty);
              }
              // return subPrice
            });
          }
          price = price + subPrice;
       })
      }else if (
        this.data.type !== "simple" &&
        this.data.variablePrices &&
        this.data.variablePrices.length &&
        keys.length
      ) {
        this.data.variablePrices.map((attr) => {


          let qty = parseInt(this.data.multiplyVariations ?  attr.qty : this.data.addQuantity ? this.cartQuantity : this.quantity);
          if(this.data.multiplyVariations && this.data.cartOrder &&  this.cartSummary.length==0){
            qty = parseInt(this.data.multiplyVariations && this.cartSummary.length>0 ? attr.qty :  this.data.addQuantity ? this.cartQuantity : this.quantity);
          }
          // let qty = parseInt(
          //   !this.data.multiplyVariations ? this.cartQuantity : attr.qty
          // );
          let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
          if (attr.name == dataVal) {
            if (!this.data.multiplyVariations && dataVal || (this.data.multiplyVariations && dataVal && this.data.cartOrder) || (this.data.multiplyVariations && !this.data.cartOrder && !this.data.addQuantity) || (this.data.multiplyVariations && !this.data.cartOrder && this.data.addQuantity)) {
              let temp = this.data.variablePrices.filter(
                (a) => a.name == dataVal
              );
              if (temp.length) {
                subPrice =
                  temp[0].priceCompare && temp[0].priceCompare != 0
                    ? temp[0].priceCompare
                    : temp[0].price;
                price = 0;
              }
              attr.whosaleData.map((one) => {
                if (one.count && parseInt(one.count) <= parseInt(qty)) {
                  subPrice = one.price;
                }
              });
              price = price + subPrice * qty;
              this.totalQuantity=this.totalQuantity+qty;
            }
          } else {
            if ((this.data.multiplyVariations && !this.data.cartOrder && !this.data.addQuantity) || (this.data.multiplyVariations && !this.data.cartOrder && this.data.addQuantity)) {
              attr.whosaleData.map((one) => {
                if (one.count && parseInt(one.count) <= parseInt(qty)) {
                  subPrice = one.price;
                }
              });
              price = price + (subPrice * qty);
              this.totalQuantity=this.totalQuantity+qty;
            }
          }
        });
      } else {
        let getQty = this.cartQuantity > 1 ? this.cartQuantity : this.quantity;
        price = this.data.priceCompare
          ? this.data.priceCompare
          : this.data.price;
        this.data.whosaleData.map((one) => {
          if (one.count && one.count <= getQty) {
            price = one.priceCompare && one.priceCompare != 0 ? one.priceCompare : one.price;
          }
        });
        price = price * getQty;
        this.totalQuantity=this.totalQuantity+getQty;
      }
      this.price = price
    },
    calcPriceWithoutWhosale() {
      let price = 0;
      let keys = Object.keys(this.payload.variablePrices);
      let dataVal = this.payload.variablePrices
        ? keys.map(o => this.payload.variablePrices[o].name).join(' / ')
        : undefined;
      if (
        this.data.type !== "simple" &&
        this.data.variablePrices &&
        this.data.variablePrices.length &&
        keys.length
      ) {
        this.data.variablePrices.map((attr) => {
          if (attr.qty) {
            // let qty = parseInt(
            //   !this.data.multiplyVariations ? this.quantity : attr.qty
            // );
            let qty = parseInt(this.data.multiplyVariations ? attr.qty : this.data.addQuantity ? this.cartQuantity : this.quantity);
            if(this.data.multiplyVariations && this.data.cartOrder && this.data.addQuantity &&  this.cartSummary.length==0){
            qty = parseInt(this.data.multiplyVariations && this.cartSummary.length>0 ? attr.qty : this.data.addQuantity ? this.cartQuantity : this.quantity);
          }
            let subPrice = attr.price;
            price = price + subPrice * qty;
          } 
        });       
        if ((!this.data.multiplyVariations ||  (this.data.multiplyVariations && this.data.cartOrder && !this.data.addQuantity)) && dataVal) {
          let temp = this.data.variablePrices.filter((a) => a.name == dataVal);
          if (temp.length) {

            let qty = this.data.addQuantity && this.cartQuantity >= 1 ? this.cartQuantity : this.quantity;
            price = temp[0].price * qty;
          }
        }
      } else {
        price = this.data.price;
        price = price * this.quantity;
      }
      this.priceWithoutWhosale = price
    },
    calcTotalPrice() {
      const isPaymentCOD = this.payload.payment === 'COD';
      const shippingType = isPaymentCOD ? this.data.shippingCOD.shippingType : this.data.shippingType;
      const additionalCost = isPaymentCOD ? this.data.shippingCOD.additionalCost : this.data.additionalCost;
      
      let price = parseFloat(this.price) + parseFloat(this.deliveryPrice ? this.deliveryPrice : 0)
      price = price + parseFloat(this.codPrice)

      if (this.shippingDiscountValue && this.data.addShipping && shippingType == "automated" && this.shippingDiscountValue > 0) {
        price = price - parseFloat(parseFloat(this.shippingDiscountValue));
      }

      if (additionalCost && this.data.addShipping && shippingType == "automated") {
        price = price + parseFloat(additionalCost)
      }
      if (this.data && this.data.isBump && this.selectedbumpArray && this.selectedbumpArray.length >= 0) {
        // price = price + parseFloat(this.data.bumpPrice)
        this.selectedbumpArray.map((bumpData) => {
          // const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            price = price + parseFloat(bumpData.bumpPrice ? bumpData.bumpPrice : 0)
          }
        })
      }
      if (this.data.isUniqueCode && (!this.payload.uniquePrice || this.payload.uniquePrice<2)) {
        // Generate a random number between 1 and 200, including both 1 and 200
        let max = price > 200 ? 200 : price;
        let min = 1;
        let r = Math.floor(Math.random() * (max - min + 1)) + min;
        this.payload.uniquePrice = r
        this.payload.uniqueType = this.data.codeType
      }
      if (this.payload.uniquePrice && this.payload.payment == 'bankTransfer') {
        price = price + (this.data.codeType == 'Decrease' ? -this.payload.uniquePrice : this.payload.uniquePrice)
      }
      if (typeof price == 'string') {
        price = parseFloat(price)
      }
      this.totalPrice = parseInt(price.toFixed())
    },
    goNext() {
      const url=window.location.href.split("/");     
      if(window.location.href.indexOf("/checkout") == -1){
        const newURl=url[0]+"//"+url[2]+"/"+url[3];       
        if (window.history && window.history.pushState) {       
          window.history.pushState('forward', null, newURl);  
          this.$router.push({
              path: "/" +url[3],
          });
        }
      }
    }, 
    calculateShippingDiscount(){
      const isPaymentCOD = this.payload.payment === 'COD';
      const shippingDiscount = isPaymentCOD ? this.data.shippingCOD.shippingDiscount : this.data.shippingDiscount;    
      this.shippingDiscountValue=0; 
      if(shippingDiscount && shippingDiscount.status){
        if(shippingDiscount.type=='percentage'){
          const percentage = (this.payload.deliveryPrice*shippingDiscount.value)/100;
          this.shippingDiscountValue=  percentage > this.payload.deliveryPrice ? this.payload.deliveryPrice : percentage;

        }else{
          this.shippingDiscountValue=  shippingDiscount.value > this.payload.deliveryPrice ? this.payload.deliveryPrice : shippingDiscount.value;
        }     
        this.payload.shippingDiscountValue= this.shippingDiscountValue;   
      } 
    },  
    updateAttributeValue(){
          this.attributes =  this.attributes
          .map((attribute) => { 
              const filteredValues = attribute.value.filter((value) => {        
                let filterI={};
                Object.keys(this.selectedAttributes).map((attributeNameF,si) => {  
                  if(attribute.name!=attributeNameF){                                
                    const selectedValues = this.selectedAttributes[attributeNameF];  
                    filterI[attributeNameF+si.toString()]=selectedValues;     
                  }else{
                    filterI[attributeNameF+si.toString()]=value;
                  }         

                })                   
                return this.filterProducts_2(this.data.variablePrices,filterI) ;   
             });
            
            if ( filteredValues.length > 0  ) {
              filteredValues.map(x=>{
                this.filterAttributes[attribute.name+"_"+x]=true
              })             

              return { ...attribute, value: filteredValues };
            }
            return attribute;                       
          }).filter(Boolean);   

       
    },     
    filterProducts_2(products,selected){
       return products.some((product) => {               
          for (const key in selected) {           
            if (selected[key]) {
              const productValue=product.attrs.find(x=>x.name==key);             
              if (productValue && productValue.value !== selected[key]) {
                return false; // Product doesn't match all selected attributes
              }
            }
          }
          
          return true && !product.delete; // Product matches all selected attributes
        });
       
    },
    initProductVariantSelect(){ 

        let variablePrices = {};
        this.data.attributes.forEach((attribute) => {        
          this.selectedAttributes[attribute.name] = "";
        });    
        this.attributes=JSON.parse(JSON.stringify(this.data.attributes));  
        this.attributes.map(attribute=>{
          attribute.value.map(x=>{           
               this.filterAttributes[attribute.name+"_"+x]=false              
            })
        })   

        this.data.variablePrices.some(item=>{        
          if(!item.delete){
            item.attrs.map((attr,index)=>{             
              variablePrices[attr.name ] = { name: attr.value, qty: 0 }
              this.selectedAttributes[attr.name.replace(index,"")] = attr.value; 
            })
            return true;
          }          
        })  
        this.payload.variablePrices = variablePrices;           
        if (Cookies.get("cartSummaryCheckout")) {
         this.cartSummary = JSON.parse(Cookies.get("cartSummaryCheckout"));
          this.data.variablePrices.map(attr => {
            let f = this.cartSummary.filter(e => e.name == attr.name);
            attr.qty = f.length > 0 ? f[0].qty : 0;
          });
          this.$forceUpdate();
        } 
        
        if (Cookies.get("tuktukSummaryCheckout")) {
            this.storeData = JSON.parse(Cookies.get("tuktukSummaryCheckout"));           
            if(this.storeData.url==this.data.url && this.$route.query.step && this.$route.query.step==2){            
              this.selectedbumpArray=[...this.selectedbumpArray,...this.storeData.selectedbumpArray];
              this.cartSummary=[...this.cartSummary,...this.storeData.cartSummary];
              this.cartQuantity=this.storeData.cartQuantity;
              this.payload.variablePrices={...this.payload.variablePrices,...this.storeData.payloadVariablePrices};
              if(this.storeData.variablePrices){
                this.storeData.variablePrices.map((item,index)=>{
                  this.data.variablePrices[index].qty=item.qty;
                })
              }  
            }        
         } 

        },
     updateAttributeValueChild(payloadVariablePrices){      
      this.attributes= JSON.parse(JSON.stringify(this.data.attributes));
      this.attributes.map(attribute=>{
        attribute.value.map(x=>{           
              this.filterAttributes[attribute.name+"_"+x]=false            
          })
      }) 
      Object.keys(payloadVariablePrices).map(key=>{        
        const attributeName = key.slice(0, -1);      
        this.selectedAttributes[attributeName]=payloadVariablePrices[key].name;              
      }) 
      this.updateAttributeValue();
           
    },
    checkVariantSelection() {    
      let filter = false;


      if(this.data.multiplyVariations){         
          let isProductDeleted =true ;
          this.cartSummary.map(item=>{
            const isProduct=this.filterProducts_2(this.data.variablePrices, item.items);
         
           if(!isProduct && isProductDeleted){
            isProductDeleted=false;
           }
           return item;
          })
         
          filter=  isProductDeleted;
          if(this.data.multiplyVariations && this.data.cartOrder){
            const selected = Object.keys(this.payload.variablePrices);
              this.data.variablePrices.some((x) => {    
                let found = [];
                selected.map((key) => {
                  const attrF = x.attrs.find(x => x.name == key);
                  const selectedValues = this.payload.variablePrices[key];         
                  found.push(attrF.value == selectedValues.name)        
                })
                if (found.filter(Boolean).length == selected.length) {
                  if (x.delete) {
                    filter = false;
                  } else {
                    filter = true;
                  }
                  return true;
                }
              });
          }
      }else{
        const selected = Object.keys(this.payload.variablePrices);
        this.data.variablePrices.some((x) => {    
          let found = [];
          selected.map((key) => {
            const attrF = x.attrs.find(x => x.name == key);
            const selectedValues = this.payload.variablePrices[key];         
            found.push(attrF.value == selectedValues.name)        
          })
          if (found.filter(Boolean).length == selected.length) {
            if (x.delete) {
              filter = false;
            } else {
              filter = true;
            }
            return true;
          }
        });
      }

      
      this.isVariableItemSelected = filter;
    },
    shouldDisplayProductVariantOnCheckout(name, attrValue) {
      return !this.data.hideUnavailableProductVariant || (this.data.hideUnavailableProductVariant && !this.checkOption(name, attrValue));
    },
    filteredProductVariant(attrValues, attrName) {
      return attrValues.filter(attrValue => this.shouldDisplayProductVariantOnCheckout(attrName, attrValue));
    },
    onSubmittedPopUpEmailConfirm() {
      this.emailConfirmModal = false;

      this.save();
    }
  },
  computed: {
    compfieldsPositionMobile() {
      return this.mobileVersion ? this.data.fieldsPositionMobile : ["singledummy"];
    },
    quantity() {
      let q = this.data.requestedFields.find(d => d.field == 'quantity')
      return parseInt(q ? q.value ? q.value : 1 : 1)
    },
    weight() {
      let weight = this.data.weight
      if (this.data.enableProductOptions && this.data.variablePrices && this.data.variablePrices.length) {
        weight = 0
        if (this.cartSummary.length > 0) {
          this.cartSummary.map(async e => {
            weight = weight + parseInt(e.weight) * parseInt(e.qty)
          })
        } else {
          this.data.variablePrices.map(one => {
            if (one.qty && parseInt(one.qty)) {
              weight = weight + parseInt(one.weight) * parseInt(one.qty)
            }
          })
        }
      } else {
        weight = weight * (this.quantity ? this.quantity : 1)
      }
      if (this.data && this.data.isBump && this.selectedbumpArray && this.selectedbumpArray.length >= 0) {
        // price = price + parseFloat(this.data.bumpPrice)
        this.selectedbumpArray.map((bumpData) => {
          // const bumpData = this.data.bumpArray.find((data) => data.bump_id == bump_id)
          if (bumpData) {
            weight = weight + parseFloat(bumpData.bumpWeight ? bumpData.bumpWeight : 0)
          }
        })
      }
      // if (this.payload.bump && this.data.bumpWeight) {
      //   weight = weight + parseInt(this.data.bumpWeight)
      // }
      return weight
    },
    deliveryPrice() {
      const isPaymentCOD = this.payload.payment === 'COD';
      const shippingType = isPaymentCOD ? this.data.shippingCOD.shippingType : this.data.shippingType;
      const flatShippingPrice = isPaymentCOD ? this.data.shippingCOD.flatShippingPrice : this.data.flatShippingPrice;
      let price = 0
      if (shippingType == 'automated') {
        price = this.payload.deliveryPrice ? this.payload.deliveryPrice : 0
      }
      if (shippingType == 'flat') {
        price = flatShippingPrice ? flatShippingPrice : 0
      }
      return parseInt(price)
    },
    codPrice() {
      let codPrice = 0;
      if (this.payload.payment == 'COD') {
        let price = this.data.CODSettings.feeType == "productShipping" ? ((this.cartSummary.length > 0 ? this.cartSummary.reduce((a, b) => a + (b.price > 0 ? b.price * b.qty : b.priceOriginal * b.qty), 0) : this.price) + this.deliveryPrice + this.data.additionalCost) : (this.cartSummary.length > 0 ? this.cartSummary.reduce((a, b) => a + (b.price > 0 ? b.price * b.qty : b.priceOriginal * b.qty), 0) : this.price);
        codPrice = parseFloat(price ? price : 0) * (+this.data.CODSettings.percent / 100)
        if (parseFloat(this.data.CODSettings.courierFeeMin) > codPrice) {
          codPrice = parseFloat(this.data.CODSettings.courierFeeMin)
        }
        if (parseFloat(this.data.CODSettings.courierFeeMax) < codPrice) {
          codPrice = parseFloat(this.data.CODSettings.courierFeeMax)
        }
      }
      return codPrice ? codPrice.toFixed() : codPrice
    },
    cogsPrice() {
      let price = 0
      let keys = Object.keys(this.payload.variablePrices)
      if (this.data.type !== 'simple' && this.data.variablePrices && this.data.variablePrices.length && keys.length) {
        this.data.variablePrices.map(attr => {
          if (attr.qty) {
            const tempPrice = attr.priceCompare ? attr.priceCompare : attr.price;
            let qty = parseInt(!this.data.multiplyVariations ? this.quantity : attr.qty)
            let subPrice = attr.costPerItem ? attr.costPerItem : tempPrice
            price = price + subPrice * qty
          }
        })
      } else {
        price = this.data.costPerItem ? this.data.costPerItem : 0
        // NOTE: CODE BELOW IS OLD CALCULATION
        // price = this.data.costPerItem ? this.data.costPerItem : this.price
        // UPDATED BASED ON THIS TICKET https://mengantar.atlassian.net/browse/MFM-142

        price = this.data.costPerItem ? this.data.costPerItem : 0
        price = price * this.quantity
      }
      return price
    },
  },
  beforeMount() {     
  
    if((this.data.nuxt2 && process.client) || !this.data.nuxt2){  
      this.initProductVariantSelect();
      let tempfilter = this.data.paymentType.filter(e => e.active)[0];
      this.payload.payment = tempfilter && this.data.showPayment ? (tempfilter.type != "E-Payment" ? tempfilter.type : (this.data && this.data.epaymentSettings && this.data.epaymentSettings[0] ? this.data.epaymentSettings[0].id : "")) : undefined;
      let self = this;

      // load split price ***** 
      if (this.data.formOption == 'multiple' && this.data.enableSplitPrice) {
        const splitPriceData = this.data.splitPriceData.find((priceData) => priceData.label == this.data.selectedSplit)
        if (splitPriceData) {
          this.data.price = splitPriceData.bidPrice //actual
          this.data.priceCompare = splitPriceData.salePrice && splitPriceData.salePrice != 0 ? splitPriceData.salePrice : null // discountedPrice
        }
      }

      this.data.bumpArray.map(bumpData=>{
        if(!('bumpColor' in bumpData) && !bumpData.bumpColor){
          bumpData.bumpColor='#203551';
        }
        return bumpData;
      })

      if(this.data.isCheckedBumpProduct && this.data.showBumpProduct && this.data.isBump){       
        this.data.bumpArray.map(bumpData=>{
          const bumpIndex=this.selectedbumpArray.findIndex(x=>x.bump_id==bumpData.bump_id);
          if(bumpIndex==-1){
            this.selectedbumpArray.push(bumpData);
          }             
        })
      }

      setTimeout(() => {
        if (this.checkout_id) {
          this.populateCheckout();
        } else {
          let currentDomain = window.location.hostname;

          if (localStorage.getItem('CheckoutCustomerInfo') || localStorage.getItem('CheckoutAddressInfo')) {

            let checkoutData = JSON.parse(localStorage.getItem('CheckoutCustomerInfo'));
            let checkoutInfo = JSON.parse(localStorage.getItem('CheckoutAddressInfo'));

            if (checkoutData[currentDomain]) {

              //this.data.requestedFields = checkoutData[currentDomain]
              this.data.requestedFields.map((attr) => {
                if (attr.active) {
                  if (attr.field == 'address') {
                    const obj = checkoutData[currentDomain].find((data) => data.field == attr.field && data.key == attr.key)
                    attr.value = obj ? obj.value : ''
                  } else {
                    const obj = checkoutData[currentDomain].find((data) => data.field == attr.field)
                    attr.value = obj ? obj.value : ''
                  }
                }
                if (checkoutInfo != null && checkoutInfo[currentDomain]) {
                  this.payload.address = checkoutInfo[currentDomain];
                  if (attr.field == 'address' && attr.key) {
                    let r = attr.key.split('_')[0]
                    attr.value = checkoutInfo[currentDomain][r] ? checkoutInfo[currentDomain][r] : false
                  }
                }
              })
            }
          }
        }
      }, 500)

      this.updatePrices();
      this.handleAutofill = debounce(async function () {
        self.createAbandon();
      }, 500);
      this.handleEstimate = debounce(async function (val) {
       await self.estimate(val);
      }, 500);
      if(this.data.showCoupon) {
        this.getDiscountLists(this.data.discount_code);
        this.getAutomaticDiscountLists();
      }
    }
  },
  async mounted() {
    if((this.data.nuxt2 && process.client) || !this.data.nuxt2){ 
      for (const property in this.shippingCourierItems) {
        let e = this.shippingCourierItems[property]
        this.courierOptionData.push({
          value: e.val,
          label: e.label2,
          label2: e.label
        })
      }
      localStorage.removeItem('orderData');
      localStorage.removeItem('checkOrderMountCount');
      localStorage.removeItem('orderDataPaymentSuccess');
      localStorage.removeItem('orderCountPaymentSuccess');
      localStorage.removeItem('orderDataPaymentConfirm');
      localStorage.removeItem('orderCountPaymentConfirm');
      this.checkCaptchaOnProduct();  //function for checking security  captcha
      let self=this;
      this.updateProductImages();

      if (self.data.settingRedirect && ((self.data.settingRedirect == 'url' && self.data.settingRedirectUrl !== '') || (self.data.settingRedirect == 'whatsappSeller' && self.data.settingRedirectWAnumber !== '') || (self.data.settingRedirect == 'whatsappSequence' && self.data.settingRedirectWAnumbers.length > 0))) { 
        // window.onbeforeunload = (event) => { this.exit(event, self) };
        window.addEventListener("popstate", (event) => {   
            this.exit(event,this);
          });  

          document.addEventListener("click", () => {
            this.goNext();
            this.confirmBox=false;
          });  
          if (navigator.userAgent.includes('Chrome') || navigator.userAgent.includes('Safari')) {
            this.confirmBox=true;
            if(this.data.settingRedirectMessage && this.data.settingRedirectMessage!=""){
              this.settingRedirectMessage=this.data.settingRedirectMessage;
            }     
          } else {
            this.confirmBox=false;
            this.goNext();
          }
      }

    // this.checkLast24HourProduct();
      this.initiateScript();
      this.createScripts();
      // default order summary position 
      this.data.summaryPlacement = this.data && this.data.summaryPlacement ? this.data.summaryPlacement : 'Before Buy Button';      
      this.calculateShippingDiscount();  
      this.updatePrices(); 
      // Check last item and if it`s not buy button show additional button [MFM-560]  
      this.data.fieldsPositionMobile=this.data.fieldsPositionMobile.filter(item=>{        
        if(item.buttonVar){
          return  this.data[item.buttonVar]
        }else{
          return true
        }
      });   
      // [MFM-1035] comment duplicate button
      // if( this.data.fieldsPositionMobile[this.data.fieldsPositionMobile.length-1].name!="buyButton"){
      //   const buyNow=this.data.fieldsPositionMobile.find(x=>x.uniqueName=="buyButton")
      //     this.data.fieldsPositionMobile.push(buyNow);
      // }  
  
      // MFM-1247 for old products
      if(this.data && (this.data.shipFrom || this.data.shipFrom == '') && this.data.multipleShipFrom.length == 0) {
        this.data.multipleShipFrom.push(this.data.shipFrom);
      }
    }
    this.isComponentReady = true;
  },
  watch: {
    'selectedbumpArray': {
      async handler() {
        this.updatePrices();
        this.handleEstimate(true);
      },
      deep: true,
    },
    "data.requestedFields": {
      handler(val) {     
        this.updatePrices(val);               
      },
      deep: true,
    },
    'payload.variablePrices': {
      async handler(val) {
        this.updatePrices();
        this.updateAttributeValueChild(val);
        this.checkVariantSelection();
        this.updateProductImages();
        
        if (this.cartSummary.length == 0 && !this.$route.query.step) {
         let keys = Object.keys(this.payload.variablePrices);
          let dataVal = this.payload.variablePrices
            ? keys.map(o => this.payload.variablePrices[o].name).join(' / ')
            : undefined;

          this.data.variablePrices.map(e => { 
            if (e.name == dataVal) { 
              e.qty = this.data.multiplyVariations  && !this.data.cartOrder ? 0 : 1
            } else {
               e.qty = 0; 
              }
          });
        }    
        this.showChoosenData();
        
      },
      deep: true,
    },
    'data.variablePrices': {
      async handler(val) {      
        this.updatePrices();
        this.handleEstimate(true)
        this.checkVariantSelection();
        this.updateProductImages();
        this.isAnyVariantwithQtyOne = val.filter(e => e.qty > 0);
      },
      deep: true,
    },
    'payload.bump': {
      async handler() {
        this.updatePrices();
        this.handleEstimate(true)
      },
      deep: true,
    },
    'weight': {
      async handler() {
        this.handleEstimate(true)
      },
    },
    'payload.address': {
      async handler() {
        this.calculateShippingDiscount();
        this.handleEstimate()
      },
      deep: true,
    },
    'payload.deliveryCourier': {
      handler(val) {
        this.payload.deliveryPrice = this.delivery[val] && this.delivery[val].length ? this.delivery[val][0].price : 0
        this.payload.deliveryType = this.delivery[val] && this.delivery[val].length && this.delivery[val][0].type ? this.delivery[val][0].type : 'REG'     
      },
    },
    "payload.deliveryPrice": {
      handler() {
        this.calculateShippingDiscount();
        this.updatePrices();
      },
    },
    "payload.payment": {
      handler() {
        this.payload.uniquePrice = undefined
        this.estimate()
        this.calculateShippingDiscount();
        this.updatePrices();
      },
    },       
    "data.shippingDiscount": {
      handler() {       
        this.calculateShippingDiscount();      
        this.updatePrices();
      },
      deep: true,
    },    
    "data.shippingCOD.shippingDiscount": {
      handler() {       
        this.calculateShippingDiscount();      
        this.updatePrices();
      },
      deep: true,
    },          
  },
}
</script>

<style lang="scss" scoped>

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
  }

  .confirm-box {
    position: absolute;
    width: 40%;
    top: 25%;
    left: 25%;
    text-align: center;
    background: white;
    border-radius: 5px;
    padding: 20px;
  }

  .close {
    cursor: pointer;
  }
.content-page-empty {
  min-height: auto !important;
}

.order-summary-list+.order-summary-list {
  margin: 15px 0 0;
  padding: 15px 0 0;
  border-top: 1px dashed #E0E9F4;
}

.embed-checkout-main.checkout-nosidebar {
  padding: 15px;
}

.checkout-nosidebar-narrow-canvas {
  &.classic {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.p-0 {
  padding: 0px !important;
}

.embed-checkout__header_nosidebar-narrow-canvas {
  margin: unset !important;
  margin-top: 0px !important;
  max-width: unset !important;
}
</style>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap');
// @import url('../../assets/fonts/inter.woff2');

// OLD CODE STYLING
@media (max-width: 768px) {
  .chooseVariant.popup>div {
    position: fixed;
    bottom: 0;
    overflow-y: auto;
  }

  .small-label.choose-variant {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #203551;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  transition: opacity 0.8s ease;
  opacity: 0;
}

.your-cart-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #203551;
}

.increDecre-btn-wrap {
  background-color: rgba(46, 71, 186, .1);
  border-radius: 0 8px 8px 0;
  padding: 7px !important;
  height: 100%;
  align-items: center;
  margin: 0 !important;
  font-size: 14px;
}

.cart-counter {
  background: #E14D4D;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  font-size: 8px;
  position: absolute;
  right: -10px;
  top: -9px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-variant-info {
  background: #ffffff;
  border: 1px solid #bfcee0;
  border-radius: 8px;
  padding: 10px;
}

.p-big {
  padding: 30px;
}

.small-side {
  background-color: #F6F8FC;
  border-radius: 6px;
}

.left-side {
  padding-left: 0;
}

.right-side {
  padding-right: 0;
}

.RightLeftsidebar-width {
  .vs-select {
    &.activeOptions {
      .vs-select__input {
        // border-color: #BFCEE0 !important;
        border: 1px solid transparent;
      }
    }
  }

  .vs-form-control input {
    border-color: #BFCEE0 !important;
  }

  .right-big-label,
  .big-label {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    color: #203551;
  }

  .spaceR {
    padding-right: 30px;
  }

  .common-font {
    &.rightLeft-big-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #203551;
    }
  }
}

.meng-card {
  @media(max-width: 767px) {
    margin: 5px auto;
  }

  @media(min-width: 768px) {
    margin: 50px auto;
  }

  .title-text {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #203551;
    max-width: 98%;
    margin: auto;
  }

  .pro_image {
    img {
      border-radius: 8px;
    }
  }

  .checkout-check {
    .vs-checkbox-label {
      color: #203551;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .copyright-texxt {
    font-size: 12px;
    line-height: 18px;
  }

  .demoTitle {
    margin: 0 0 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.02em;
    color: #203551;
  }

  .sumPro-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #203551;
  }

  .checkout-form {
    flex: 0 0 calc(100% - 410px);
    width: calc(100% - 410px);
    padding: 0;

    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.02em;
      color: #203551;
    }

    @media (max-width: 991px) {
      flex: 0 0 100%;
      width: 100%;
      padding: 0;
    }
  }

  .checkout-detail {
    flex: 0 0 380px;
    width: 380px;
    padding: 0;

    &.minimalist {
      padding: 0;

      @media (max-width: 575px) {
        padding: 0;
      }

      .shoe-detail {
        background-color: #F6F8FC;
        border-radius: 6px;
        padding: 15px;
        text-align: left;
      }
    }

    &.classic-right {
      padding: 0;

      @media (max-width: 991px) {
        padding: 0;
      }
    }

    &.classic-left {
      padding: 0;

      @media (max-width: 991px) {
        padding: 0;
      }

      @media (max-width: 575px) {
        padding: 0;
      }
    }

    @media (max-width: 991px) {
      flex: 0 0 100%;
      width: 100%;
    }
  }


  .shoe-detail {
    border-radius: 8px;
  }

  .b-title {
    margin-bottom: 15px;
  }

  .order-summary {
    margin: 20px 0 0;
  }

  .vs-input {
    border: 1px solid #BFCEE0;
    background-color: #fff !important;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 13px;
    color: #6C7E95;
    line-height: 18px;
    min-height: 40px;
    font-weight: 400;

    &::placeholder {
      color: #6C7E95 !important;
    }
  }

  .Nosidebar-container {
    &.bottom-bar-text {
      @media(max-width: 575px) {
        padding-bottom: 0;
        margin-top: 20px;
      }
    }
  }
}

.sumPro-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #203551;
}

.summary-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #6C7E95;
}

.summary-total {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #203551;
}

.summary-tax {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #5D6F86;
  text-align: left;
}

.common-font {
  font-weight: 400;
  letter-spacing: 0.02em;

  &.title-under-image {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #203551;
  }

  &.tagline-title {
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #6C7E95;
    margin: 0;
  }

  &.seal-title {
    margin: 0 0 0 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    text-transform: uppercase;
    color: #6C7E95;

    @media (max-width: 576px) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &.product-desc {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #6C7E95;
  }

  &.big-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #203551;
    margin: 0;
  }

  &.bullet-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #203551;
    text-align: left;
  }

  &.bullet-name2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #203551;
  }

  &.order-summary-header {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #6C7E95;
  }

  &.product-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #203551;
  }

  &.regular_price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #203551;
  }

  &.sale_price {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: line-through;
    color: #FA5757;
  }

  &.total {
    font-weight: 600;
    color: #203551;

    &.title {
      font-size: 16px;
      line-height: 19px;
    }

    &.price {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.pro_image img {
  width: -webkit-fill-available;
}

.bullet-img {
  margin-right: 12px;
  // width: 18px;
  // height: 18px;
}

.center-side {
  background: #F6F8FC;
  border-radius: 8px;
}

.Nosidebar-width {
  max-width: 540px;
  width: 100%;
}

.nosidebar-narrow-canvas-width {
  width: 100%;
  margin: auto !important;

  @media screen and (min-width: 561px) {
    max-width: 400px !important;
  }
}

.RightLeftsidebar-width {
  max-width: 1140px;
  width: 100%;
}

.demoTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #203551;
}

.order-details {
  border-radius: 8px !important;
}

.embed-leftHide .leftTemp-seal {
  display: none;
}

@media screen and (max-width: 767px) {
  .embed-leftSidebar-popup-width {
    max-width: 353px;
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .embed-leftSidebar-popup-width {
    max-width: 460px;
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .Nosidebar-width,
  .RightLeftsidebar-width {
    .preview-main {
      padding: 20px 20px;
    }
  }
}

@media screen and (max-width: 767px) {
  .Nosidebar-width,
  .RightLeftsidebar-width {
    padding: 0px 5px;

    .common-font {
      &.rightLeft-big-label {
        font-size: 19px;
      }
    }

    .mobile-img {
      .mr-1 {
        img {
          width: 32px;
        }
      }
    }
  }
  .Nosidebar-width,
  .RightLeftsidebar-width {
    .preview-main {
      padding: 20px 15px;
    }
  }
}

.options-group {
  display: grid;
  grid-gap: 15px;

  &.grid-cols-1 {
    grid-template-columns: 1fr;
  }

  &.grid-cols-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.options-wrap .qty-input input.vs-input {
  width: 60px !important;
  background: none !important;
  border: none !important;
  text-align: center;
  color: #203551 !important;
  font-weight: 500;
}

.options-wrap .qty-input {
  opacity: 1;
}

.options-wrap .option-name {
  padding: 0 15px;
}

.big-label {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: #203551;
  margin-bottom: 15px !important;
}

.product-desc+.get-container {
  margin: 20px 0 0;
}

.checkout-info+.get-container {
  margin: 20px 0 0;
}

.get-container .common-font.big-label {
  margin-bottom: 0 !important;
}

.get-content {
  margin: 15px 0 0;
}

.get-list+.get-list {
  margin: 10px 0 0;
}

.get-list .bullet-name2 {
  line-height: 20px;
}


.testimonial-container {
  margin: 0;
  padding: 20px 0 0;
  border-top: 1px solid #E0E9F4;
}

.testimonial-container.classic {
  margin: 0;
  padding: 0;
  border-top: 0;

  @media (max-width: 991px) {
    margin-top: 25px;
    padding: 0;
  }
}

.checkout-container {
  &.classic {
    @media (max-width: 767px) {
      padding: 0;
    }

    @media (max-width: 575px) {
      padding: 0;
    }
  }
}

.testimonial-wrapper.classic {
  padding: 20px 0;
  // background-color: #F6F8FC;
  // border-radius: 8px;
}

.save-nextTime .vs-input {
  min-height: 18px;
}

.testimonials-list+.testimonials-list {
  margin: 10px 0 0;
  padding: 10px 0 0;
  border-top: 1px solid #E0E9F4;
}

.Nosidebar-container {
  padding-bottom: 0;
}





// embed-popup 
.popup-main-wrapper>div {
  @media(max-width: 575px) {
    padding: 0 !important;
  }
}

.popup-main-wrapper {
  @media(max-width: 575px) {
    margin-top: 28px;
    margin-bottom: 12px;
  }

  @media(min-width: 576px) {
    padding: 28px 20px 0px;
  }
}

.embed-popup-footer {
  margin-top: 20px;

  @media(min-width: 576px) {
    margin-bottom: 20px;
  }
}

.popup-form>div {
  border-radius: 12px;
}

.popup-form>div>button {
  @media(max-width: 575px) {
    top: -39px !important;
    right: 9px !important;
  }

  @media(min-width: 576px) {
    top: -10px !important;
    right: -10px !important;
  }
}

.popup-form>div>button .vs-icon-close::after,
.popup-form>div>button .vs-icon-close::before {
  margin-top: 15px;
  right: 10px;
}

.noMarginInMobile>div {
  @media(max-width: 575px) {
    margin: 0;
  }
}

.embed-rightSidebar-popup-footer {
  @media(max-width: 575px) {
    margin: 20px auto 10px;
  }

  @media(min-width: 576px) {
    margin: 20px auto 20px;
  }
}

.embed-leftSidebar-popup-footer {
  margin: 20px auto 20px;
}

.copyright-content--leftsidebar--mobile{
  display: block;
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

.embed-checkout-main__container {
  &.main-container-canvas-narrow {
    // border: 1px solid #dadada;
    width: 100%;
    max-width: 100%;
    border-radius: 12px;
    padding: 0px;
    background-color: #ffffff;

    @media screen and (min-width: 561px) {
      max-width: 100%;
    }

    .bottom-component {
      margin-top: 0px !important;
      .testimonial-container {
        .testimonial-title-bottom.classic {
          margin-top: 0px !important;
          margin-bottom: 0px !important;
        }
      }
    }
  }
}
.checkout-nosidebar-narrow-canvas {
  .main-container-canvas-narrow {
    &.is-mobile {
      .testimonial-wrapper {
          .testi-arrows.slick-prev {
            top: -27% !important;
            left: 88% !important;
          }

          .testi-arrows.slick-next {
            top: -27% !important;
            left: 95% !important;
          }
        }
    }
    &:not(.is-mobile) {
      .testimonial-wrapper {
        .testi-arrows.slick-prev {
          top: -54% !important;
          left: 88% !important;
        }

        .testi-arrows.slick-next {
          top: -54% !important;
          left: 95% !important;
        }
      }
    }
  }
}

.pop-up-email-confirmation {
  .vs-dialog {
    .vs-dialog__content {
      padding: 24px;

      .main-content-email-confirm {
        display: flex;
        flex-direction: column;
        gap: 14px;

        .header {
          display: flex;
          flex-direction: column;

          .title {
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
            text-align: left;
            color: #203551;
          }

          .subtitle {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: #6C7E95;
          }
        }

        .form-email {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .label-email {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            text-align: left;
          }

          .input-email {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            text-align: left;
            color: #203551;
            width: 100%;
            height: 44px;
            border-radius: 8px;
            border: 1px solid #E0E9F4;

            label {
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #6C7E95;
            }

            &.is-invalid {
              border: 1px solid #F53535;
            }
          }

          .invalid-email {
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            text-align: left;
            color: #F53535;
          }
        }

        .instruction {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          height: 40px;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #6C92E4;
          box-shadow: 0px 1.5px 4px -1px #0A090B12;

          .label {
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #4272DD;
          }
        }

        .button-container {
          display: flex;

          .confirm-button {
            width: 100%;
            height: 40px;
            padding: 10px 16px;
            border-radius: 8px;
            border: 1px solid #F6F8FC;
            background-color: #F6F8FC;

            color: #6C7E95;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            text-align: center;

            &.save {
              background-color: #2E47BA;
              color: #ffffff;
              box-shadow: 0px 1px 2px 0px #0000001F;
            }

            &.disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">

 @import '@/assets/styles/pages/checkout/index.scss';
 @import '@/assets/styles/pages/checkout/components/form/right.scss';
 @import '@/assets/styles/pages/checkout/components/form/left.scss';
</style> 
